import { Action, handleActions } from 'redux-actions'
import { IPage } from 'shared/utils/types'
import { Type } from './actions'
import { Order, OrderEvents, OrderItem, OrdersState, OrderList, OrderStatus } from './types'
import { Payload } from 'shared/utils/types';

export const defaultPayload = {
  page: 0,
  size: 20,
  sort: "creationDate,desc"
}

const INITIAL_STATE: OrdersState = {
  loading: false,
  requestPayload: defaultPayload,
  orders: undefined,
  searchByRef: {
    loading: false,
    data: undefined
  },
  orderByRef: {
    loading: false,
    data: undefined,
  },
  orderThings: {
    loadingEvents: false,
    loadingItems: false,
    orderItems: undefined,
    orderEvents: undefined
  },
  lastSingleOrderUpdated: undefined,
  lastVisitedTabOrders: undefined,
  bulkUpdateOrders: { loading: false, lastOrdersUpdated: undefined },
  selectedRows: [],
  navigationSelectedKey: [''],
  showNavigation: false,
  massActionStatus: OrderStatus.Solded,
  columns: undefined
}

export default handleActions<OrdersState, any>(
  {
    [Type.SET_COLUMNS]: (state: OrdersState, action: Action<any>) => ({
      ...state,
      columns: action.payload
    }),
    [Type.LOAD_ORDERS]: (state: OrdersState, action: Action<Payload>) => ({
      ...state,
      loading: true,
      requestPayload: action.payload,
      orders: undefined
    }),
    [Type.LOAD_ORDERS_SUCCESS]: (state: OrdersState, action: Action<IPage<Order>>) => ({
      ...state,
      loading: false,
      orders: action.payload
    }),
    [Type.LOAD_ORDERS_ERROR]: (state: OrdersState) => ({
      ...state,
      loading: false,
      orders: undefined,
      lastSingleOrderUpdated: undefined,
      bulkUpdateOrders: undefined
    }),
    [Type.PUT_ORDER]: (state: OrdersState) => ({
      ...state,
    }),
    [Type.PUT_ORDER_SUCCESS]: (state: OrdersState, action: Action<Order>) => {
      const orders = state.orders && state.orders.content ? {
        ...state.orders,
        content: state.orders?.content.map((o: Order) => (o.id === action.payload.id) ? { ...action.payload } : o)
      } : undefined

      return ({
        ...state,
        lastSingleOrderUpdated: action.payload,
        orders
      })
    },
    [Type.PUT_ORDERS]: (state: OrdersState) => ({
      ...state,
      bulkUpdateOrders: { loading: true, lastOrdersUpdated: undefined }
    }),
    [Type.PUT_ORDERS_SUCCESS]: (state: OrdersState, action: Action<OrderList>) => ({
      ...state,
      lastSingleOrderUpdated: undefined,
      bulkUpdateOrders: { loading: false, lastOrdersUpdated: action.payload.orders },
    }),
    [Type.PUT_ORDERS_ERROR]: (state: OrdersState) => ({
      ...state,
      bulkUpdateOrders: { loading: false, lastOrdersUpdated: undefined }
    }),
    [Type.SET_SELECTED_ORDERS]: (state: OrdersState, action: Action<[Order]>) => ({
      ...state,
      selectedRows: action.payload
    }),
    [Type.ORDER_MASSACTION_STATUS]: (state: OrdersState, action: Action<number>) => ({
      ...state,
      massActionStatus: action.payload
    }),
    [Type.TOGGLE_NAVIGATION_MENU]: (state: OrdersState) => ({
      ...state,
      showNavigation: !state.showNavigation
    }),
    [Type.NAVIGATION_SELECTED_KEY]: (state: OrdersState, action: Action<['']>) => ({
      ...state,
      navigationSelectedKey: action.payload
    }),
    [Type.GET_ORDER_ITEMS]: (state: OrdersState) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingItems: true,
        orderItems: undefined
      }
    }),
    [Type.GET_ORDER_ITEMS_SUCCESS]: (state: OrdersState, action: Action<Array<OrderItem>>) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingItems: false,
        orderItems: action.payload
      }
    }),
    [Type.GET_ORDER_ITEMS_ERROR]: (state: OrdersState) => ({
      ...state,
      loading: false,
      orderThings: {
        ...state.orderThings,
        loadingItems: false,
        orderItems: undefined
      },
    }),
    [Type.GET_ORDER_ITEMS_WITH_STOCKS]: (state: OrdersState) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingItems: true,
        orderItems: undefined
      }
    }),
    [Type.GET_ORDER_ITEMS_WITH_STOCKS_SUCCESS]: (state: OrdersState, action: Action<OrderItem[]>) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingItems: false,
        orderItems: action.payload
      }
    }),
    [Type.GET_ORDER_ITEMS_WITH_STOCKS_ERROR]: (state: OrdersState) => ({
      ...state,
      loading: false,
      orderThings: {
        ...state.orderThings,
        loadingItems: false,
        orderItems: undefined
      },
    }),
    [Type.GET_ORDER_EVENTS]: (state: OrdersState) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingEvents: true,
        orderEvents: undefined
      }
    }),
    [Type.GET_ORDER_EVENTS_SUCCESS]: (state: OrdersState, action: Action<OrderEvents[]>) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingEvents: false,
        orderEvents: action.payload
      }
    }),
    [Type.GET_ORDER_EVENTS_ERROR]: (state: OrdersState) => ({
      ...state,
      loading: false,
      orderThings: {
        ...state.orderThings,
        loadingEvents: false,
        orderEvents: undefined
      },
    }),
    [Type.GET_BY_REF]: (state: OrdersState) => ({
      ...state,
      orderByRef: {
        loading: true,
        data: undefined,
      },
      orderThings: undefined
    }),
    [Type.GET_BY_REF_SUCCESS]: (state: OrdersState, action: Action<Order>) => ({
      ...state,
      orderByRef: {
        ...state.orderByRef,
        loading: false,
        data: action.payload
      }
    }),
    [Type.GET_BY_REF_ERROR]: (state: OrdersState) => ({
      ...state,
      loading: false,
      orderByRef: {
        ...state.orderByRef,
        loading: false,
        data: undefined,
      },
    }),
    [Type.SEARCH_BY_REF]: (state: OrdersState) => ({
      ...state,
      searchByRef: {
        loading: true,
        data: undefined,
      },
      orderThings: undefined
    }),
    [Type.SEARCH_BY_REF_SUCCESS]: (state: OrdersState, action: Action<Order>) =>
    ({
      ...state,
      searchByRef: {
        ...state.searchByRef,
        loading: false,
        data: action.payload
      }
    })
    ,
    [Type.SEARCH_BY_REF_ERROR]: (state: OrdersState) => ({
      ...state,
      loading: false,
      searchByRef: {
        ...state.searchByRef,
        loading: false,
        data: undefined,
      },
    }),
    [Type.RETRANSMISSION_TO_DMS]: (state: OrdersState) => ({
      ...state,
      orderByRef: {
        loading: true,
        data: undefined,
        orderThings: undefined
      }
    }),
    [Type.RETRANSMISSION_TO_DMS_SUCCESS]: (state: OrdersState, action: Action<Order>) => ({
      ...state,
      orderByRef: {
        ...state.orderByRef,
        loading: false,
        data: action.payload
      }
    }),
    [Type.RETRANSMISSION_TO_DMS_ERROR]: (state: OrdersState) => ({
      ...state,
      orderByRef: {
        ...state.orderByRef,
        loading: false,
        data: undefined,
      },
    }),
    [Type.LAST_VISITED_TAB_ORDERS]: (state: OrdersState, action: Action<string>) => ({
      ...state,
      lastVisitedTabOrders: action.payload
    }),
    [Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST]: (state: OrdersState) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingItems: true
      }
    }),
    [Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_SUCCESS]: (state: OrdersState) => ({
      ...state
    }),
    [Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_ERROR]: (state: OrdersState) => ({
      ...state,
      orderThings: {
        ...state.orderThings,
        loadingItems: false
      }
    }),
  },
  INITIAL_STATE
)
