import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'shared/routes/ProtectedRoute';
import { userIsAuthenticated } from '../login/stores/selectors';
import {
  BottomPart,
  Container,
  MainContainer,
  MainWrapper,
  RPNavigation,
} from 'shared/GlobalStyles';
import { AccountsNavigation } from './navigation/AccountsNavigation';
import { myRoleListSelector } from 'screens/context/stores';
import { PageProps } from 'shared/utils/types';

/**
 * Account Component - Used to Manage Accounts
 */
export const Accounts: FC<PageProps> = ({ routes }) => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);

  return (
    <MainContainer>
      <MainWrapper>
        {(!isAuthenticated || roles === undefined) || (
          <Container>
            <RPNavigation>
              <AccountsNavigation />
            </RPNavigation>
            <Switch>
              {routes.map((route, key) => (
                <ProtectedRoute key={key} {...route} />
              ))}
            </Switch>
          </Container>
        )}
        <BottomPart></BottomPart>
      </MainWrapper>
    </MainContainer>
  );
};
