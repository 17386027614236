import { User } from 'oidc-client';

export interface LoginPayload {
  idp: string
  password: string
}

export type BIRCode = string | undefined

/**
 * Extended user
 */
export class RpUser extends User {

  get bircode(): BIRCode {
    if (this.profile.entity_identifiers) {
      const entity_identifiers: Array<string> | string = this.profile.entity_identifiers
      const temp_bircode = typeof entity_identifiers === "string" ? entity_identifiers.split(";") : entity_identifiers[0].split(";")
      const bircode = temp_bircode[temp_bircode.length - 1]
      return bircode
    } else return undefined
  }

}

export interface LoginState {
  loading: boolean
  user?: RpUser
  isSidebarVisible: boolean
}