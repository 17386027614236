import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { Menu } from 'antd';
import { FlagOutlined } from '@ant-design/icons';
import { StyledMenu } from '../Styles';

export const TranslateMenu: FC = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <StyledMenu key="menu" mode="horizontal" selectedKeys={['']}>
            <Menu.SubMenu
                key="1"
                icon={<FlagOutlined />}
            >
                <Menu.Item
                    key="/french"
                    title={t("Generic.Languages.French")}
                    onClick={() => changeLanguage('fr')}
                    style={{ backgroundColor: i18n.language === 'fr' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="FR"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.French")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.French")}
                </Menu.Item>
                <Menu.Item
                    key="/dutch"
                    title={t("Generic.Languages.Dutch")}
                    onClick={() => changeLanguage('nl')}
                    style={{ backgroundColor: i18n.language === 'nl' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="NL"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Dutch")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Dutch")}
                </Menu.Item>
                <Menu.Item
                    key="/english"
                    title={t("Generic.Languages.English")}
                    onClick={() => changeLanguage('en')}
                    style={{ backgroundColor: i18n.language === 'en' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="GB"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.English")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.English")}
                </Menu.Item>
                <Menu.Item
                    key="/german"
                    title={t("Generic.Languages.German")}
                    onClick={() => changeLanguage('de')}
                    style={{ backgroundColor: i18n.language === 'de' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="DE"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.German")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.German")}
                </Menu.Item>
                <Menu.Item
                    key="/de-at"
                    title={t("Generic.Languages.German")}
                    onClick={() => changeLanguage('de-at')}
                    style={{ backgroundColor: i18n.language === 'de-at' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="AT"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.GermanAT")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.GermanAT")}
                </Menu.Item>
                <Menu.Item
                    key="/italian"
                    title={t("Generic.Languages.Italian")}
                    onClick={() => changeLanguage('it')}
                    style={{ backgroundColor: i18n.language === 'it' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="IT"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Italian")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Italian")}
                </Menu.Item>
                <Menu.Item
                    key="/polish"
                    title={t("Generic.Languages.Polish")}
                    onClick={() => changeLanguage('pl')}
                    style={{ backgroundColor: i18n.language === 'pl' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="PL"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Polish")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Polish")}
                </Menu.Item>
                <Menu.Item
                    key="/portuguese"
                    title={t("Generic.Languages.Portuguese")}
                    onClick={() => changeLanguage('pt')}
                    style={{ backgroundColor: i18n.language === 'pt' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="PT"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Portuguese")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Portuguese")}
                </Menu.Item>
                <Menu.Item
                    key="/romanian"
                    title={t("Generic.Languages.Romanian")}
                    onClick={() => changeLanguage('ro')}
                    style={{ backgroundColor: i18n.language === 'ro' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="RO"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Romanian")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Romanian")}
                </Menu.Item>
                <Menu.Item
                    key="/russian"
                    title={t("Generic.Languages.Russian")}
                    onClick={() => changeLanguage('ru')}
                    style={{ backgroundColor: i18n.language === 'ru' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="RU"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Russian")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Russian")}
                </Menu.Item>
                <Menu.Item
                    key="/spanish"
                    title={t("Generic.Languages.Spanish")}
                    onClick={() => changeLanguage('es')}
                    style={{ backgroundColor: i18n.language === 'es' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="ES"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Spanish")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Spanish")}
                </Menu.Item>
                <Menu.Item
                    key="/turkish"
                    title={t("Generic.Languages.Turkish")}
                    onClick={() => changeLanguage('tr')}
                    style={{ backgroundColor: i18n.language === 'tr' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="TR"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Turkish")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Turkish")}
                </Menu.Item>
                <Menu.Item
                    key="/ukrainian"
                    title={t("Generic.Languages.Ukrainian")}
                    onClick={() => changeLanguage('uk')}
                    style={{ backgroundColor: i18n.language === 'uk' ? '#F1F1F1' : 'inherit', marginTop: '0px' }}
                >
                    <ReactCountryFlag
                        countryCode="UA"
                        cdnSuffix="svg"
                        title={t("Generic.Languages.Ukrainian")}
                        style={{ marginRight: '10px' }}
                        svg
                    />
                    {t("Generic.Languages.Ukrainian")}
                </Menu.Item>
            </Menu.SubMenu>
        </StyledMenu>
    );
}