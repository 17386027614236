import { Route } from 'shared/utils/types';
import { ErrorLogsTracking } from './pages/ErrorLogsTracking';

export const ErrorLogsRoutes: Route[] = [
  {
    name: "tracking",
    path: "/tracking",
    component: ErrorLogsTracking,
    isProtected: true,
    isBircodeRequired: true
  }
];
