import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as Effects from 'redux-saga/effects'
import { API_TIMEOUT, DEFAULT_DELAY } from './constants'
import { actionsCreators as apiActions } from 'stores/api/actions'
import { actionCreators as alertActions } from '../components/RPAlert/stores/actions'
import { createGenericErrorAlert, getLoginUser, getRefreshedUser } from './'
import store from "stores/store"
import { actionCreators } from 'screens/login/stores/actions'

const { call, put, delay } = Effects

export const api = axios.create({
  // baseURL: API_BASE_URL_ORACLE,
  timeout: API_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    apikey: process.env.REACT_APP_APIKEY
  }
})

// Request interceptor for API calls
api.interceptors.request.use(
  async config => {
    if (!config.url?.startsWith('/oauth')) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${store.getState().rp4.login.user?.access_token}`
      }

      addTimeZoneOffset(config);
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    return config
  },
  error => Promise.reject({ ...error })
)

let isReconnecting = false;

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (isReconnecting) {
      return {
        ...error.config,
        cancelToken: new axios.CancelToken((cancel: any) => cancel('Cancel repeated request'))
      };
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      isReconnecting = true
      originalRequest._retry = true;
      await getRefreshedUser();
      return api(originalRequest);
    } else if (error.response.status === 401 && originalRequest._retry) {
      await getLoginUser();
      return api(originalRequest)
    } else {
      store.dispatch(actionCreators.authLogout())
      return Promise.reject({ ...error })
    }

  }
)

const addTimeZoneOffset = (config: AxiosRequestConfig) => {
  if (!config.url?.includes("timeZoneOffset")) {
    const timeZoneOffset: string = `timeZoneOffset=${- (new Date().getTimezoneOffset() / 60)}`;

    if (config.url?.includes("?"))
      config.url += `&${timeZoneOffset}`
    else
      config.url += `?${timeZoneOffset}`
  }
}

export function* apiCallWrapper<T>(fn: any, ...rest: any[]): any {
  try {
    yield put(apiActions.loadingStart())
    const response: T = yield call(fn, ...rest)
    yield delay(DEFAULT_DELAY)
    yield put(apiActions.loadingSuccess())
    return response
  } catch (err) {
    yield put(apiActions.loadingFail(err))
    yield put(alertActions.alertMsg(createGenericErrorAlert(err)))
  } finally {
    yield delay(DEFAULT_DELAY)
    yield put(apiActions.loadingClear())
  }
}

export function unwrapAxiosResult<T>(promise: AxiosPromise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<T>) => {
        resolve(response.data)
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export default api