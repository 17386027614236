import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'shared/routes/ProtectedRoute';
import { userIsAuthenticated } from '../login/stores/selectors';
import { OrdersNavigation } from './OrdersNavigation';
import {
  BottomPart,
  Container,
  MainContainer,
  MainWrapper,
  RPNavigation,
} from 'shared/GlobalStyles';
import { PageProps } from 'shared/utils/types';
import { myRoleListSelector } from 'screens/context/stores';

/**
 * Orders main component - Used to manage orders
 */
const Orders: FC<PageProps> = ({ routes }) => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);

  return (
    <MainContainer>
      <MainWrapper>
        {(isAuthenticated || roles !== undefined) && (
          <Container>
            <RPNavigation>
              <OrdersNavigation />
            </RPNavigation>

            <Switch>
              {routes.map((route, key) => (
                <ProtectedRoute key={key} {...route} />
              ))}
            </Switch>
          </Container>
        )}

        <BottomPart></BottomPart>
      </MainWrapper>
    </MainContainer>
  );
};

export default Orders;
