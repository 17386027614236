import { Select, Form, Divider, Row, Col, Checkbox } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDmsFluxList } from "../hooks/useDmsFluxList";
import { useDmsTypeList } from "../hooks/useDmsTypeList";
import { useLogisticLogic } from "../hooks/useLogisticLogic";
import { useRegionalStockList } from "../hooks/useRegionalStockList";

export const SellerFormDmsInfo: FC = () => {
    const { t } = useTranslation();
    const { Option } = Select;
    const regionalStockList = useRegionalStockList();
    const dmsTypeList = useDmsTypeList();
    const dmsFluxList = useDmsFluxList();
    const { wholesalerLogistic, directLogistic } = useLogisticLogic();

    const gutter = 16;
    const titleSpan = 5;
    const fieldSpan = 2;

    return <>
        <Divider orientation="left">{t("Account.Details.DmsInfo")}</Divider>
        {wholesalerLogistic && <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.WholesalerLogistic")} :</span></Col>
            <Form.Item name={["accountDmsR1", "wholesalerLogistic"]} valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
        </Row>}
        {directLogistic && <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DirectLogistic")} :</span></Col>
            <Form.Item name={["accountDmsR1", "directLogistic"]} valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>
        </Row>}
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsStocksState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmsstocksstate"]}>
                    <Select>
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsCatalogState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmscatalogstate"]}>
                    <Select>
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsPriceState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmspricestate"]}>
                    <Select>
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsCustomerProductDiscState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmscustomerproductdiscstate"]}>
                    <Select>
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsCustomerGroupDiscState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmscustomergroupdiscstate"]}>
                    <Select >
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsPersonnalRateState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmspersonnalratestate"]}>
                    <Select >
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsCountryDiscountState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmscountrydiscountstate"]}>
                    <Select >
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.DmsFinancialStatusState")} :</span></Col>
            <Col span={fieldSpan}>
                <Form.Item name={["accountDmsR1", "dmsfinancialstatusstate"]}>
                    <Select >
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                        <Option value={"I"}>I</Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}>
                <span>{t("Account.Details.RegionalStock")} :</span>
            </Col>
            <Col span={5}>
                <Form.Item
                    name={["accountDmsR1", "regionalstock"]}
                    rules={[{ required: true }]}
                >
                    <Select>
                        {
                            regionalStockList.map(regionalStock =>
                                <Option key={regionalStock.value} value={regionalStock.value}>{regionalStock.label}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={5}>
                <span>{t("Account.Details.DmsType")} :</span>
                <Form.Item name={["accountDmsR1", "dmstype"]}>
                    <Select>
                        {
                            dmsTypeList.map(dmsType =>
                                <Option key={dmsType.value} value={dmsType.value}>{dmsType.label}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={5}>
                <span>{t("Account.Details.DmsOnlineFlag")} :</span>
                <Form.Item name={["accountDmsR1", "dmsonlineflag"]}>
                    <Select >
                        <Option value={"Y"}>Y</Option>
                        <Option value={"N"}>N</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={5}>
                <span>{t("Account.Details.DmsFlux")} :</span>
                <Form.Item name={["accountDmsR1", "dmsflux"]}>
                    <Select>
                        {
                            dmsFluxList.map(dmsFlux =>
                                <Option key={dmsFlux.value} value={dmsFlux.value}>{dmsFlux.label}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>

        </Row>
    </>
}