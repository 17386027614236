import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { myRoleListSelector } from 'screens/context/stores';
import {
  BottomPart,
  Container,
  MainContainer,
  MainWrapper,
  RPNavigation,
} from 'shared/GlobalStyles';
import { PageProps } from 'shared/utils/types';
import ProtectedRoute from 'shared/routes/ProtectedRoute';
import { userIsAuthenticated } from '../login/stores/selectors';
import { ErrorLogsNavigation } from './ErrorLogsNavigation';

/**
 * ErrorLogs main component - Used to manage Errors
 */
const ErrorLogs: FC<PageProps> = ({ routes }) => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);

  return (
    <MainContainer>
      <MainWrapper>
        {(!isAuthenticated || roles === undefined) || (
          <Container>
            <RPNavigation>
              <ErrorLogsNavigation />
            </RPNavigation>

            <Switch>
              {routes.map((route, key) => (
                <ProtectedRoute key={key} {...route} />
              ))}
            </Switch>
          </Container>
        )}

        <BottomPart></BottomPart>
      </MainWrapper>
    </MainContainer>
  );
};

export default ErrorLogs;
