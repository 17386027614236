import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as accountActions, myCountriesDataSelector } from 'screens/accounts/stores';
import { BIRCodeDataListSelector, myRoleListSelector, myRolePrivilegeSelector, RolePrivilege } from 'screens/context/stores';
import { actionCreators as loginActionCreators } from 'screens/login/stores/actions';
import {
  userIsAuthenticated
} from 'screens/login/stores/selectors';
import * as utils from 'screens/login/stores/utils';
import { actionCreators as alertActions } from 'shared/components/RPAlert/stores/actions';
import { getBirCode } from 'shared/utils';
import {
  ContentWrapper, PrimaryHeader
} from '../Styles';
import { HeaderApp } from './HeaderApp';
import { HeaderLogin } from './HeaderLogin';

/**
 * Black Header Component
 */
export const Header: FC = () => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);
  const rolePrivilege = useSelector(myRolePrivilegeSelector);
  const dispatch = useDispatch();

  const countries = useSelector(myCountriesDataSelector);
  const BIRCodeDataList = useSelector(BIRCodeDataListSelector);

  useEffect(() => {
    if (!countries && isAuthenticated && roles) dispatch(accountActions.loadCountries());
    if (isAuthenticated && roles && rolePrivilege === RolePrivilege.R1) {
      let disconnect = true;
      const bircode = getBirCode();

      if (bircode && BIRCodeDataList) {
        BIRCodeDataList.forEach((bircodeData) => {
          if (bircodeData.bircode === bircode) disconnect = false;
        })

        if (disconnect) {
          dispatch(loginActionCreators.authLogout())
          dispatch(alertActions.alertMsg(utils.createAlert(
            {
              message: {
                statusText: "Unauthorized"
              }
            }
          )))
        }
      }

    }
  }, [countries, isAuthenticated, roles, rolePrivilege])

  return (
    <PrimaryHeader>
      <ContentWrapper>
        {!isAuthenticated || roles === undefined ? <HeaderApp /> : <HeaderLogin />}
      </ContentWrapper>
    </PrimaryHeader>
  );
};
