import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { CustomItem } from 'shared/GlobalStyles';
import { actionCreators, toggleNavigation } from '../stores';
import { ToggleButton, ToggleWrapper } from '../Styles';

export const AccountsNavigation: FC = () => {
  const { url } = useRouteMatch();
  const { t } = useTranslation()
  const showMenu = useSelector(toggleNavigation);
  const dispatch = useDispatch();
  const toggleCollapsed = () => dispatch(actionCreators.toggleNavigationOpen());
  return (
    <Menu mode="inline" inlineCollapsed={showMenu}>
      <ToggleWrapper $collapsed={showMenu}>
        <ToggleButton
          onClick={toggleCollapsed}
          type="primary"
          $collapsed={showMenu}
        >
          {React.createElement(
            showMenu ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </ToggleButton>
      </ToggleWrapper>

      <CustomItem key="/tracking" icon={<UserAddOutlined />}>
        <NavLink to={`${url}/tracking`}>{t("Account.Navigation.R1LinkedAccount")}</NavLink>
      </CustomItem>

    </Menu>
  );
};
