import { Spin } from "antd";
import { useSelector } from "react-redux";
import { useCountry } from "screens/accounts/hooks/useCountry";
import { orderByRefDataSelector } from "screens/orders/stores";
import { getSelectedCountry } from "shared/utils";
import store from "stores/store";

export const usePriceRender = (value: string | number) => {
    const orderByRefData = useSelector(orderByRefDataSelector)
    const country = useCountry();

    const isLoading = (!country && !orderByRefData) ? true : false

    let res = "";
    if (country) res = `${value} ${country.currencycode ? country.currencycode : ""}`
    else res = `${value} ${orderByRefData?.currencyCode ? orderByRefData?.currencyCode : ""}`

    return isLoading ? "..." : res
}

export const PriceRender = (props: { value: string | number }) => {
    return <>{usePriceRender(props.value) === "..." ? <Spin /> : props.value}</>
}

export const priceRender = (value: string | number) => {
    const orderByRefData = store.getState().rp4.orders.orderByRef.data;
    const country = getSelectedCountry();
    let res = "";
    if (country) res = `${value} ${country.currencycode ? country.currencycode : ""}`
    else res = `${value} ${orderByRefData?.currencyCode ? orderByRefData?.currencyCode : ""}`
    return res
}