import { AutoComplete, AutoCompleteProps } from "antd"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { BircodeData, BIRCodeDataListSelector, isRolePrivilegeGranted, RolePrivilege, actionCreators, garagePopoverVisibleSelector, garagePopoverValueSelector } from "../stores"
import { SelectLabel, SelectWrapper } from "../Styles"
import { push } from 'connected-react-router'
import { useLocation } from 'react-router-dom'

export const GarageSelect: FC = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()

    const visible = useSelector(garagePopoverVisibleSelector)
    const setVisible = (visible: boolean) => dispatch(actionCreators.setGaragePopoverVisible(visible))

    const bircodeDataList = useSelector(BIRCodeDataListSelector)
    const value = useSelector(garagePopoverValueSelector)
    const setValue = (value: string) => dispatch(actionCreators.setGaragePopoverValue(value))

    useEffect(() => {
        if (!visible) setValue("")
    }, [visible])

    const autoCompleteProps: AutoCompleteProps = {
        autoFocus: true,
        defaultActiveFirstOption: true,
        value,
        style: { "width": "450px" },
        placeholder: t(`Countries.garagePlaceholder`),
        filterOption: (inputValue, option) => {
            if (option && option.value)
                return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            return false
        },
        options: bircodeDataList?.map(bircodeData => ({
            value: `${bircodeData.businessName} - ${bircodeData.bircode} - ${bircodeData.city}`,
            key: bircodeData.bircode
        })),
        onSelect: (value: string) => {
            const garage: Array<string> = value.split(" - ")

            // default value 99.9% cases
            let businessName = garage[0];
            let city = garage[2];
            let bircodeIndex = 1;
            let bircode = garage[bircodeIndex];

            // others cases
            // finding bircode
            while (
                (isNaN(Number(bircode))) || (bircode.length !== 8)) {
                bircodeIndex++;
                bircode = garage[bircodeIndex];
            }
            // finding businessName & city
            if (bircodeIndex !== 1) {
                businessName = garage.slice(0, bircodeIndex).join(" - ")
                city = garage.slice(bircodeIndex + 1).join(" - ")
            }

            const bircodeData: BircodeData = {
                businessName,
                bircode,
                city
            }

            if (value) {
                localStorage.setItem('bircodeData', JSON.stringify(bircodeData));
                dispatch(actionCreators.setMyBirCode(bircodeData));
                setValue("");
                setVisible(false);

                location.pathname !== "/home" && dispatch(push("/home"))
            }
            return
        },
        onChange: (value) => setValue(value)
    }

    const showBirCodeDataSelect = isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION) && bircodeDataList;

    if (!showBirCodeDataSelect) return <></>

    return <SelectWrapper>
        <SelectLabel>{t(`Countries.chooseAGarage`)}</SelectLabel>
        <AutoComplete {...autoCompleteProps} />
    </SelectWrapper>

}