import { ColumnsType } from "antd/lib/table";
import { useRowAsLink } from "shared/hooks/useRowAsLink";
import { Payload } from "shared/utils/types";
import { Order, OrderStatus } from "../stores/types";
import { defaultPayload } from "../stores/reducer";
import { useOrderColumns } from "./useOrderColumns";
import { useOrderStatusFilterDropdown } from "./useOrderStatusFilterDropdown";

export function useOrderTable() {
    const initColumns = useOrderColumns();
    const statusColumns = useOrderStatusFilterDropdown();

    const defaultColumns = initColumns.map((column) => {
        if (column.key === "status")
            column = {
                ...column,
                ...statusColumns
            }

        return column;
    })

    function useTableExporter<T>(columns: ColumnsType<T>, payload: Payload = {}) {
        payload = { ...defaultPayload, ...payload }
        columns = useRowAsLink(columns, (record: Order) => record.orderRef.toString())

        return {
            columns,
            payload
        }
    }

    const ordersAll = useTableExporter(defaultColumns)

    const ordersInProgressTable = useTableExporter(initColumns, {
        status: [OrderStatus.DMSTreatment.toString()]
    })

    const ordersWaitingForActionTable = useTableExporter(initColumns, {
        status: [OrderStatus.ValidationRequired.toString()]
    })

    const ordersNeedReissueDMS = useTableExporter(initColumns, {
        reissueDMS: true
    })

    const ordersFinishedTable = useTableExporter(initColumns, {
        status: [
            OrderStatus.Solded.toString(),
            OrderStatus.ExaTechnicalIssue.toString(),
            OrderStatus.FullyServed.toString(),
            OrderStatus.DMSRefuse.toString(),
            OrderStatus.R1Refuse.toString(),
        ]
    })

    return {
        ordersWaitingForActionTable,
        ordersInProgressTable,
        ordersNeedReissueDMS,
        ordersAll,
        ordersFinishedTable
    }
}