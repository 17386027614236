import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRoutes } from 'shared/routes/AppRoutes';
import ProtectedRoute from 'shared/routes/ProtectedRoute';
import { history } from 'stores';
import { Header } from 'shared/components/Header/Header';
import { Footer } from 'shared/components/Footer/Footer';
import './App.less';
import { FullScreenHeight, Container } from './Styles';
import { SecondaryHeader } from 'shared/components/Header/SecondaryHeader';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import { myRoleListSelector } from 'screens/context/stores';
import { RPAlert } from 'shared/components/RPAlert/RPAlert';
import { useConfigTranslate } from './shared/hooks/useConfigTranslate';
import { ConfigProvider, Spin } from 'antd';
import { myCountriesLoadingSelector } from 'screens/accounts/stores';

export default function App() {
  const mainRoutesContainer = getRoutes().map(({ ...props }, key) => (
    <ProtectedRoute key={key} {...props} />
  ));
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector)
  const locale = useConfigTranslate();
  const loading = useSelector(myCountriesLoadingSelector)

  return (
    <ConnectedRouter history={history}>
      <ConfigProvider locale={locale}>
        <FullScreenHeight id="App">
          <RPAlert />
          <Spin spinning={loading}>
            <Container>
              <Header />
              {(!isAuthenticated || roles === undefined) && <SecondaryHeader />}
              <Switch>{mainRoutesContainer}</Switch>
              <Footer />
            </Container>
          </Spin>
        </FullScreenHeight>
      </ConfigProvider>
    </ConnectedRouter>
  );
}
