import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import frFR from 'antd/lib/locale/fr_FR';
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import deDE from "antd/lib/locale/de_DE";
import ptPT from "antd/lib/locale/pt_PT";
import plPL from "antd/lib/locale/pl_PL";
import nlNL from "antd/lib/locale/nl_NL";
import itIT from "antd/lib/locale/it_IT";
import roRO from "antd/lib/locale/ro_RO";
import ruRU from "antd/lib/locale/ru_RU";
import trTR from "antd/lib/locale/tr_TR";
import ukUK from "antd/lib/locale/uk_UA";

const updateMomentLang = (language: string) => {
    moment.updateLocale("fr", {
        months:
            'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
                '_'
            ),
        monthsShort:
            'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split('_'),
        monthsParseExact: true,
        weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
        weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact: true,
        longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd D MMMM YYYY à HH:mm',
        },
        calendar: {
            sameDay: '[Aujourd’hui à] LT',
            nextDay: '[Demain à] LT',
            nextWeek: 'dddd [à] LT',
            lastDay: '[Hier à] LT',
            lastWeek: 'dddd [dernier à] LT',
            sameElse: 'L',
        },
        relativeTime: {
            future: 'dans %s',
            past: 'il y a %s',
            s: 'quelques secondes',
            m: 'une minute',
            mm: '%d minutes',
            h: 'une heure',
            hh: '%d heures',
            d: 'un jour',
            dd: '%d jours',
            M: 'un mois',
            MM: '%d mois',
            y: 'un an',
            yy: '%d ans',
        },
        dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
        ordinal: function (number) {
            return number + (number === 1 ? 'er' : 'e');
        },
        meridiemParse: /PD|MD/,
        isPM: function (input) {
            return input.charAt(0) === 'M';
        },
        meridiem: function (hours, minutes, isLower) {
            return hours < 12 ? 'PD' : 'MD';
        },
        week: {
            dow: 1,
            doy: 4,
        },
    });
    moment.locale(language);
}

/**
 * Config Provider
 */
export const useConfigTranslate = () => {
    const [locale, setLocale] = useState<any>();
    const { i18n } = useTranslation();
    const updateAntdLang = useCallback(() => {
        const language: any = {
            "fr": frFR,
            "en": enUS,
            "es": esES,
            "de": deDE,
            "pt": ptPT,
            "nl": nlNL,
            "it": itIT,
            "pl": plPL,
            "ro": roRO,
            "ru": ruRU,
            "tr": trTR,
            "uk": ukUK,
            "de-at": deDE
        };
        setLocale(language[i18n.language] || language['fr']);
    }, [i18n.language]);

    useEffect(() => {
        updateAntdLang();
        updateMomentLang(i18n.language);
    }, [i18n.language, updateAntdLang])

    return locale
}
