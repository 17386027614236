import { createSelector } from 'reselect'
import { RootState } from 'stores'

export const stockExchangeStateSelector = (state: RootState) => state.rp4.stockExchange

export const stockExchangeLoadingSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeLoading)
export const stockExchangePayloadSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangePayload)
export const stockExchangeDataSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeData)
export const stockExchangeColumnsSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeColumns)

export const stockExchangeLnkLoadingSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeLnkLoading)
export const stockExchangeLnkPayloadSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeLnkPayload)
export const stockExchangeLnkDataSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeLnkData)
export const stockExchangeLnkColumnsSelector = createSelector(stockExchangeStateSelector, state => state.stockExchangeLnkColumns)

export const toggleNavigation = createSelector(stockExchangeStateSelector, state => state.showNavigation)
export const navSelectedKey = createSelector(stockExchangeStateSelector, state => state.navigationSelectedKey)