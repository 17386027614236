import { FormProps } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sellerDataSelector, actionCreators } from "../stores";

export function useSellerForm(): FormProps {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    interface IFormProps extends FormProps {
        key?: number // used to refresh UI each time data is submitted
    }

    const seller = useSelector(sellerDataSelector);
    const [formProps, setFormProps] = useState<IFormProps>({});
    const [key, setKey] = useState(0);

    useEffect(() => {
        seller && setFormProps({
            key: key,
            onFinish: (values) => {
                setKey(key + 1)
                dispatch(actionCreators.updateSellerDmsR1({
                    ...seller,
                    openingTime: {
                        weekdayOpenHour: values.openingTime.weekdayOpenHour ? values.openingTime.weekdayOpenHour.format('HH:mm:ss') : '',
                        weekdayClosedHour: values.openingTime.weekdayClosedHour ? values.openingTime.weekdayClosedHour.format('HH:mm:ss') : '',
                        saturdayOpenHour: values.openingTime.saturdayOpenHour ? values.openingTime.saturdayOpenHour.format('HH:mm:ss') : '',
                        saturdayClosedHour: values.openingTime.saturdayClosedHour ? values.openingTime.saturdayClosedHour.format('HH:mm:ss') : '',
                        sundayOpenHour: values.openingTime.sundayOpenHour ? values.openingTime.sundayOpenHour.format('HH:mm:ss') : '',
                        sundayClosedHour: values.openingTime.sundayClosedHour ? values.openingTime.sundayClosedHour.format('HH:mm:ss') : '',
                    },
                    accountData: { ...seller.accountData, ...values.accountData },
                    accountDmsR1: { ...seller.accountDmsR1, ...values.accountDmsR1 },
                }));
            },

            validateMessages: {
                required: t("Generic.Alert.RequiredField")
            },

            initialValues: {
                ...seller,
                openingTime: {
                    weekdayOpenHour: seller.openingTime.weekdayOpenHour ? moment(seller.openingTime.weekdayOpenHour, 'HH:mm:ss') : '',
                    weekdayClosedHour: seller.openingTime.weekdayClosedHour ? moment(seller.openingTime.weekdayClosedHour, 'HH:mm:ss') : '',
                    saturdayOpenHour: seller.openingTime.saturdayOpenHour ? moment(seller.openingTime.saturdayOpenHour, 'HH:mm:ss') : '',
                    saturdayClosedHour: seller.openingTime.saturdayClosedHour ? moment(seller.openingTime.saturdayClosedHour, 'HH:mm:ss') : '',
                    sundayOpenHour: seller.openingTime.sundayOpenHour ? moment(seller.openingTime.sundayOpenHour, 'HH:mm:ss') : '',
                    sundayClosedHour: seller.openingTime.sundayClosedHour ? moment(seller.openingTime.sundayClosedHour, 'HH:mm:ss') : '',
                }
            },

            className: "form-account-details"

        })

    }, [seller])

    return formProps

}