import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import stringFilterDropdown from 'shared/components/Filters/StringFilterDropdown';
import { useRowAsLink } from 'shared/hooks/useRowAsLink';
import { Buyer } from '../stores/types';

export function useBuyersColumns(): ColumnsType<Buyer> {
    const { t } = useTranslation()

    const DEFAULT_COLUMNS: ColumnsType<Buyer> = [{
        title: t("Account.Columns.businessname"),
        key: 'businessname',
        dataIndex: 'businessname',
        ...stringFilterDropdown('Account.Columns.businessname'),
        sorter: true
    },
    {
        title: t("Account.Columns.SellersTable.tresorcode"),
        key: 'tresorcode',
        dataIndex: "tresorcode",
        ...stringFilterDropdown('Account.Columns.SellersTable.tresorcode'),
        sorter: true
    },
    {
        title: t("Account.Columns.bircode"),
        dataIndex: "bircode",
        key: 'bircode',
        ...stringFilterDropdown('Account.Columns.bircode'),
        sorter: true
    },
    {
        title: t("Account.Columns.validitystartdate"),
        key: 'acc.subbegindate',
        render: (record: Buyer) => { return record.accountbuyers[0].validationStartDate ? moment(record.accountbuyers[0].validationStartDate).format('L') : null },
        sorter: true,
    },
    {
        title: t("Account.Columns.validityenddate"),
        key: 'acc.subenddate',
        render: (record: Buyer) => { return record.accountbuyers[0].validationEndDate ? moment(record.accountbuyers[0].validationEndDate).format('L') : null },
        sorter: true,
    },
    {
        title: t("Account.Columns.clienttype"),
        dataIndex: 'clienttype',
        key: 'clienttype',
        sorter: true
    },
    {
        title: t("Account.Columns.clientcode"),
        key: 'clientcode',
        render: record => record.accountClientCodes[0].clientcode,
        ...stringFilterDropdown('Account.Columns.clientcode'),
    }
    ]

    return useRowAsLink<Buyer>(DEFAULT_COLUMNS, (record: Buyer) => `buyer/${record.id.toString()}`)
}