import { ColumnsType } from "antd/lib/table";
import { Action, handleActions } from "redux-actions";
import { IPage, Payload } from "shared/utils/types";
import { Type } from "./actions";
import { Seller, DmsAccountInfo, Buyer, AccountsState, Country, BuyersAction, BuyerSubscribeResponse } from "./types";

export const defaultBuyerPayload: Payload = {
  page: 0,
  size: 20
}

const INITIAL_STATE: AccountsState = {
  seller: {
    loading: false,
    data: undefined
  },

  buyers: {
    loading: false,
    data: undefined,
    columns: undefined,
    payload: defaultBuyerPayload,
    lastVisitedTab: undefined,

    payloadById: undefined,
    dataById: undefined,
    loadingById: false,

    selectedRows: [],
    buyersMassActionType: BuyersAction.SUBSCRIBE,
  },

  countries: {
    loading: false,
    data: undefined
  },
  showNavigation: false,
}

export default handleActions<AccountsState, any>(

  {
    [Type.LOAD_COUNTRIES]: (state: AccountsState) => ({
      ...state,
      countries: {
        loading: true,
        data: undefined,
      }
    }),
    [Type.LOAD_COUNTRIES_SUCCESS]: (state: AccountsState, action: Action<Country[]>) => ({
      ...state,
      countries: {
        loading: false,
        data: action.payload,
      }
    }),
    [Type.LOAD_COUNTRIES_ERRORS]: (state: AccountsState) => ({
      ...state,
      countries: {
        loading: false,
        data: undefined,
      }
    }),
    // GET Accounts
    [Type.LOAD_SELLER_BY_BIRCODE]: (state: AccountsState) => ({
      ...state,
      seller: {
        data: undefined,
        loading: true
      }
    }),
    [Type.LOAD_SELLER_BY_BIRCODE_SUCCESS]: (state: AccountsState, action: Action<Seller>) => ({
      ...state,
      seller: {
        data: action.payload,
        loading: false
      }
    }),
    [Type.LOAD_SELLER_BY_BIRCODE_ERROR]: (state: AccountsState) => ({
      ...state,
      seller: {
        data: undefined,
        loading: false
      }
    }),
    // GET BUYERS
    [Type.LOAD_BUYERS_BY_BIRCODE]: (state: AccountsState, action: Action<Payload>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        data: undefined,
        loading: true,
        payload: action.payload
      }
    }),
    [Type.LOAD_BUYERS_BY_BIRCODE_SUCCESS]: (state: AccountsState, action: Action<IPage<Buyer>>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        data: action.payload,
        loading: false
      }
    }),
    [Type.LOAD_BUYERS_BY_BIRCODE_ERROR]: (state: AccountsState) => ({
      ...state,
      buyers: {
        ...state.buyers,
        data: undefined,
        loading: false
      }
    }),
    // GET SELLER BY ID
    [Type.LOAD_BUYER_BY_ID]: (state: AccountsState, action: Action<number>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        dataById: undefined,
        loadingById: true,
        payloadById: action.payload
      }
    }),
    [Type.LOAD_BUYER_BY_ID_SUCCESS]: (state: AccountsState, action: Action<Buyer>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        dataById: action.payload,
        loadingById: false
      }
    }),
    [Type.LOAD_BUYER_BY_ID_ERROR]: (state: AccountsState) => ({
      ...state,
      buyers: {
        ...state.buyers,
        dataById: undefined,
        loadingById: false
      }
    }),
    [Type.UPDATE_BUYER]: (state: AccountsState) => ({
      ...state,
      buyers: {
        ...state.buyers,
        loadingById: true
      }
    }),
    [Type.UPDATE_DMS_INFO_SUCCESS]: (state: AccountsState, action: Action<DmsAccountInfo>) => {
      let newSeller = state.buyers.dataById;
      if (newSeller)
        newSeller.dmsAccountInfos[0] = action.payload;

      return {
        ...state,
        buyers: {
          ...state.buyers,
          loadingById: false,
          dataById: newSeller
        }
      }
    },
    [Type.UPDATE_DMS_INFO_ERROR]: (state: AccountsState, action: Action<DmsAccountInfo>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        loadingById: false
      }
    }),
    [Type.UPDATE_SELLER_DMS_R1]: (state: AccountsState) => ({
      ...state,
      seller: {
        ...state.seller,
        loading: true
      }
    }),
    [Type.UPDATE_SELLER_DMS_R1_SUCCESS]: (state: AccountsState, action: Action<Seller>) => ({
      ...state,
      seller: {
        loading: false,
        data: action.payload
      }
    }),
    [Type.UPDATE_SELLER_DMS_R1_ERROR]: (state: AccountsState) => ({
      ...state,
      seller: {
        ...state.seller,
        loading: false
      }
    }),
    // User Prefs
    [Type.TOOGLE_NAVIGATION_MENU]: (state: AccountsState) => ({
      ...state,
      showNavigation: !state.showNavigation
    }),
    // Subscribe MASS
    [Type.SUBSCRIBE_BUYER_MASS]: (state: AccountsState) => ({
      ...state,
      buyers: {
        ...state.buyers,
        loading: true
      }
    }),
    [Type.SUBSCRIBE_BUYER_MASS_SUCCESS]: (state: AccountsState, action: Action<BuyerSubscribeResponse[]>) => {

      if (action.payload.length === 1
        && state.buyers.dataById
        && state.buyers.dataById.accountbuyers[0].id === action.payload[0].id
        && action.payload[0].statusMessage === "OK"
      ) {
        state.buyers.dataById.accountbuyers[0].unsubscribeAllowed = true;
        state.buyers.dataById.accountbuyers[0].subscribeAllowed = false;
        state.buyers.dataById.accountbuyers[0].validationStartDate = action.payload[0].validityStartDate;
        state.buyers.dataById.accountbuyers[0].validationEndDate = action.payload[0].validityEndDate;
      }

      let buyers = state.buyers.data;
      let subscribedBuyers = action.payload;
      subscribedBuyers.forEach((newBuyer) => {
        buyers?.content.forEach((buyer, bId) => {
          if (buyer.accountbuyers[0].id === newBuyer.id && buyers && buyers.content && newBuyer.statusMessage === "OK") {
            buyers.content[bId].accountbuyers[0].validationStartDate = newBuyer.validityStartDate
            buyers.content[bId].accountbuyers[0].validationEndDate = newBuyer.validityEndDate
          }
        })
      });

      return {
        ...state,
        buyers: {
          ...state.buyers,
          loading: false,
          data: buyers
        }
      }
    },
    [Type.SUBSCRIBE_BUYER_MASS_ERROR]: (state: AccountsState) => {
      return {
        ...state,
        buyers: {
          ...state.buyers,
          dataById: undefined,
          loadingById: false
        }
      }
    },
    // Unsubscribe mass
    [Type.UNSUBSCRIBE_BUYER_MASS]: (state: AccountsState) => ({
      ...state,
      buyers: {
        ...state.buyers,
        loading: true
      }
    }),
    [Type.UNSUBSCRIBE_BUYER_MASS_SUCCESS]: (state: AccountsState, action: Action<BuyerSubscribeResponse[]>) => {

      if (action.payload.length === 1 && state.buyers.dataById && state.buyers.dataById.accountbuyers[0].id === action.payload[0].id && action.payload[0].statusCode === 0) {
        state.buyers.dataById.accountbuyers[0].unsubscribeAllowed = false;
        state.buyers.dataById.accountbuyers[0].subscribeAllowed = true;
        state.buyers.dataById.accountbuyers[0].validationStartDate = action.payload[0].validityStartDate;
        state.buyers.dataById.accountbuyers[0].validationEndDate = action.payload[0].validityEndDate;
      }

      let buyers = state.buyers.data;
      let subscribedBuyers = action.payload;
      subscribedBuyers.forEach((newBuyer) => {
        buyers?.content.forEach((buyer, bId) => {
          if (buyer.accountbuyers[0].id === newBuyer.id && buyers && buyers.content) {
            buyers.content[bId].accountbuyers[0].validationStartDate = newBuyer.validityStartDate
            buyers.content[bId].accountbuyers[0].validationEndDate = newBuyer.validityEndDate
          }
        })
      });

      return {
        ...state,
        buyers: {
          ...state.buyers,
          loading: false,
          data: buyers,
          dataById: state.buyers.dataById
        }
      }
    },
    [Type.UNSUBSCRIBE_BUYER_MASS_ERROR]: (state: AccountsState) => ({
      ...state,
      buyers: {
        ...state.buyers,
        loadingById: false
      }
    }),
    [Type.SET_BUYER_COLUMNS]: (state: AccountsState, action: Action<ColumnsType<Buyer>>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        columns: action.payload
      }
    }),
    [Type.SET_BUYER_PAYLOAD]: (state: AccountsState, action: Action<Payload>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        payload: action.payload
      }
    }),
    [Type.SET_BUYER_LAST_VISITED_TAB]: (state: AccountsState, action: Action<string>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        lastVisitedTab: action.payload
      }
    }),
    [Type.SET_BUYER_SELECTED_ROWS]: (state: AccountsState, action: Action<Buyer[]>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        selectedRows: action.payload
      }
    }),
    [Type.SET_BUYER_ACTION_TYPE]: (state: AccountsState, action: Action<BuyersAction>) => ({
      ...state,
      buyers: {
        ...state.buyers,
        buyersMassActionType: action.payload
      }
    })
  },
  INITIAL_STATE
)