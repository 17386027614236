import { FC } from "react"
import { FileTextTwoTone, MailTwoTone } from '@ant-design/icons';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { actionCreators, Order } from '../stores';
import { MessageContainer } from '../Styles';
import { useTranslation } from "react-i18next";

interface OrderTableMessageProps {
  message: string;
  order: Order;
}

export const OrderTableMessage: FC<OrderTableMessageProps> = ({ message, order }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const title = `${t(`Orders.Others.Order`)} ${order.orderRef}`;
  const content = (
    <MessageContainer>
      <p>{message}</p>
    </MessageContainer>
  );

  const MarkAsRead = () => {
    if (order) {
      const updateOrder: Order = {
        ...order,
        isMessageToSellerRead: true,
      };

      dispatch(actionCreators.updateOrder(updateOrder));
    }
  };

  return (
    <Popover trigger="click" placement="left" title={title} content={content}>
      {order.isMessageToSellerRead ? <FileTextTwoTone /> : <MailTwoTone onClick={MarkAsRead} />}
    </Popover>
  );
};
