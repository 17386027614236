import { createSuccessMsg, apiCallWrapper } from 'shared';
import * as actions from './actions'
import { ID, IPage, IRestData } from 'shared/utils/types';
import { Order, OrderEvents, OrderItem, UpdateOrderItems, OrderList } from './types';
import { Payload } from 'shared/utils/types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { actionCreators as alertActions } from 'shared/components/RPAlert/stores/actions'
import { retransmissionDMS, getOrders, putOrder, putOrders, orderItemsWantedQuantity, getOrderItemsByOrderId, getOrderEventsByOrderId, getOrderItemsWithStocksByOrderId, getByRef } from './utils';
import i18n from 'assets/i18n';
import store from "stores/store"
import { push } from 'connected-react-router';
import { actionCreators as contextActions } from "screens/context/stores/actions"
import { RolePrivilege } from 'screens/context/stores/types';
import { isRolePrivilegeGranted } from 'screens/context/stores/utils';

function* loadOrders(action: Action<Payload>) {
  try {
    const orderPage: IPage<Order> = yield apiCallWrapper<IPage<Order>>(getOrders, action.payload)
    yield put(actions.actionCreators.loadOrdersSuccess(orderPage))
  } catch (error) {
    yield put(actions.actionCreators.loadOrdersError(error))
  }
}

function* updateOrder(action: Action<Order>) {
  try {
    const updated: Order = yield apiCallWrapper(putOrder, action.payload)
    yield put(actions.actionCreators.updateOrderSuccess(updated))
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Orders.Alert.StatusUpdated"))))
    yield put(actions.actionCreators.getByRef(updated.orderRef))
  } catch (error) {
    yield put(actions.actionCreators.loadOrdersError(error))
  }
}

function* refreshOrders() {
  let PageablePayload = store.getState().rp4.orders.requestPayload;
  yield put(actions.actionCreators.loadOrders(PageablePayload))
}

function* updateOrders(action: Action<OrderList>) {
  try {

    const updated: OrderList = yield apiCallWrapper(putOrders, action.payload)
    yield put(actions.actionCreators.updateOrdersSuccess(action.payload))
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Generic.Alert.RowsUpdated", { numberRows: updated.orders.length.toString() }))))
    yield refreshOrders();
  } catch (error) {
    yield put(actions.actionCreators.updateOrdersError(error))
  }
}

function* getOrderItems(action: Action<ID>) {
  try {
    const orderItems: IRestData<OrderItem> = yield apiCallWrapper(getOrderItemsByOrderId, action.payload)
    yield put(actions.actionCreators.getOrderItemsSuccess(orderItems))
  } catch (error) {
    yield put(actions.actionCreators.getOrderItemsError(error))
  }
}

function* getOrderItemsWithStocks(action: Action<ID>) {
  try {
    const orderItemsWithStocks: IRestData<OrderItem> = yield apiCallWrapper(getOrderItemsWithStocksByOrderId, action.payload)
    yield put(actions.actionCreators.getOrderItemsWithStocksSuccess(orderItemsWithStocks))
  } catch (error) {
    yield put(actions.actionCreators.getOrderItemsWithStocksError(error))
  }
}

function* getOrderEvents(action: Action<ID>) {
  try {
    const orderEvents: Array<OrderEvents> = yield apiCallWrapper(getOrderEventsByOrderId, action.payload)
    yield put(actions.actionCreators.getOrderEventsSuccess(orderEvents))
  } catch (error) {
    yield put(actions.actionCreators.getOrderEventsError(error))
  }
}

function* getOrderByRef(action: Action<string>) {
  try {
    const ref = action.payload
    const orderByRef: Order = yield apiCallWrapper(getByRef, ref)
    yield put(actions.actionCreators.getByRefSuccess(orderByRef))
    yield call(getOrderThingsByOrder, orderByRef)
  } catch (error) {
    yield put(actions.actionCreators.getByRefError(error))
  }
}

function* getOrderThingsByOrder(order: Order) {
  // Order items
  if (order.status === 0) yield put(actions.actionCreators.getOrderItemsWithStocks(order.id))
  else yield put(actions.actionCreators.getOrderItems(order.id))

  // Order events
  yield put(actions.actionCreators.getOrderEvents(order.id))
}

function* searchOrderByRef(action: Action<string>) {
  try {
    const ref = action.payload
    const orderByRef: Order = yield apiCallWrapper(getByRef, ref)
    yield put(actions.actionCreators.searchByRefSuccess(orderByRef))

    if (orderByRef) {
      yield put(push(`/orders/${orderByRef.orderRef}`))
      yield put(contextActions.setOrderPopoverVisible(false));

      if (isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION))
        yield put(contextActions.setToEmptyMyBirCode())

    } else
      yield put(contextActions.setOrderPopoverMsg(i18n.t("Countries.ThisOrderDoesNotExistOrIsInaccessible")))

  } catch (error) {
    yield put(actions.actionCreators.searchByRefError(error))
  }
}

function* updateOrderRetransmissionDMS(action: Action<number>) {
  try {
    const order: Order = yield apiCallWrapper<Order>(retransmissionDMS, action.payload)
    yield put(actions.actionCreators.retransmissionToDMSSuccess(order));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Orders.Alert.RetransmittedToDMS"))));
  } catch (error) {
    yield put(actions.actionCreators.retransmissionToDMSError(error))
  }
}

function* updateOrderItemsWantedQuantity(action: Action<UpdateOrderItems>) {
  try {
    const orderItems: OrderItem = yield apiCallWrapper<OrderItem>(orderItemsWantedQuantity, action.payload)
    yield put(actions.actionCreators.updateOrderItemsWantedQuantitySuccess(orderItems))
  } catch (error) {
    yield put(actions.actionCreators.updateOrderItemsWantedQuantityError(error))
  }
}

function* updateOrderItemsWantedQuantityList(action: Action<UpdateOrderItems[]>) {
  try {
    for (const item of action.payload) {
      yield apiCallWrapper<OrderItem>(orderItemsWantedQuantity, item)
    }
    yield put(actions.actionCreators.updateOrderItemsWantedQuantityListSuccess());
    yield put(actions.actionCreators.getOrderItemsWithStocks(action.payload[0].orderId));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Orders.Alert.WantedQuantityUpdated"))));
  } catch (error) {
    yield put(actions.actionCreators.updateOrderItemsWantedQuantityError(error))
  }
}

export default function* () {
  yield takeLatest(actions.Type.LOAD_ORDERS, loadOrders)
  yield takeLatest(actions.Type.PUT_ORDER, updateOrder)
  yield takeLatest(actions.Type.PUT_ORDERS, updateOrders)
  yield takeLatest(actions.Type.GET_ORDER_ITEMS, getOrderItems)
  yield takeLatest(actions.Type.GET_ORDER_ITEMS_WITH_STOCKS, getOrderItemsWithStocks)
  yield takeLatest(actions.Type.GET_ORDER_EVENTS, getOrderEvents)
  yield takeLatest(actions.Type.SEARCH_BY_REF, searchOrderByRef)
  yield takeLatest(actions.Type.GET_BY_REF, getOrderByRef)
  yield takeLatest(actions.Type.RETRANSMISSION_TO_DMS, updateOrderRetransmissionDMS)
  yield takeLatest(actions.Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY, updateOrderItemsWantedQuantity)
  yield takeLatest(actions.Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST, updateOrderItemsWantedQuantityList)
}