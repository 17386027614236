/*
Configuration of component protected route that serve all the path of front application
Handle protected or non protected routes according to route description in routesDef file
*/
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getBirCode } from '../';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import { myRoleListSelector } from 'screens/context/stores';

const ProtectedRoute = ({ isProtected, isBircodeRequired, ...props }: any) => {

  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);

  const checkProtection = () => {

    if (!isProtected || ((isAuthenticated && roles !== undefined) && (!isBircodeRequired || getBirCode())))
      return <props.component {...props} />
    else if ((isProtected && !isAuthenticated) || roles === undefined)
      return <Redirect from="/" to={{ pathname: '/login' }} />
    else
      return <Redirect from="/" to={{ pathname: '/home' }} />
  }

  return <Route {...props} exact component={checkProtection} key={1} />;
};

export default ProtectedRoute;
