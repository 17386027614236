import iconPhoneNumber from 'assets/img/icon-phone.svg';
import iconUser from 'assets/img/icon-user.svg';
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actionCreators } from 'screens/login/stores/actions';
import {
    CallRate, ConnectHeaderWrapper,
    ConnectWrapper, IconPhoneNumber, IconUser, IconUserWrapper, LoginButton, PhoneNumber, PhoneNumberWrapper
} from '../Styles';

export const HeaderApp: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const connect = () => dispatch(actionCreators.authLogin())

    return <ConnectHeaderWrapper>
        <ConnectWrapper>
            <IconUserWrapper>
                <IconUser src={iconUser} />
            </IconUserWrapper>
            <LoginButton
                onClick={() => connect()}
                title={t("loginPage.LogToBO")}
            >
                {t("Generic.Actions.LogIn")}
            </LoginButton>
        </ConnectWrapper>
        <PhoneNumberWrapper>
            <IconPhoneNumber src={iconPhoneNumber} />
            <PhoneNumber>{t("loginPage.phoneNumber")}</PhoneNumber>
            <CallRate>{t("loginPage.callRate")}</CallRate>
        </PhoneNumberWrapper>
    </ConnectHeaderWrapper>
}