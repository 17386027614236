import {
    combineReducers,
    configureStore,
    getDefaultMiddleware
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import rootSaga from './rootSaga'
import rootReducer from './rootReducer'

/**
 * Root Store 
 */
export const history = createBrowserHistory()

// Create SAGA Middleware
const sagaMiddleware = createSagaMiddleware()

const middlewares = [
    ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true
    }),
    routerMiddleware(history),
    sagaMiddleware,
    // thunkMiddleware,
    createLogger({ collapsed: true })
]

const createRootReducer = combineReducers({
    router: connectRouter(history),
    rp4: rootReducer
})

// Configure Store
const store = configureStore({
    reducer: createRootReducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production'
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof createRootReducer>
export type AppDispatch = typeof store.dispatch

export default store
