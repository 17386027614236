import i18n from 'assets/i18n';
import { Action } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { actionCreators as contextActions, RolePrivilege } from "screens/context/stores";
import { apiCallWrapper, createSuccessMsg } from "shared";
import { actionCreators as alertActions } from 'shared/components/RPAlert/stores/actions';
import { ID, IPage, Payload } from "shared/utils/types";
import store from 'stores/store';
import { getBuyerById, getCountries, patchDmsR1 } from ".";
import { actionCreators, Type } from "./actions";
import { Buyer, BuyerSubscribeRequest, BuyerSubscribeResponse, Country, DmsAccountInfo, Seller } from "./types";
import {
  getBuyersByBirCode,
  getSellerByBirCode,
  postSubscribeMass,
  postUnsubscribeMass,
  putDmsInfo
} from "./utils";

import { actionCreators as loginActions } from "screens/login/stores/actions"

function* loadSellerByBirCode() {
  try {
    const account: Seller = yield apiCallWrapper<Seller>(getSellerByBirCode)
    yield put(actionCreators.loadSellerByBirCodeSuccess(account))
  } catch (error) {
    yield put(actionCreators.loadSellerByBirCodeError(error))
  }
}

function* loadBuyersByBirCode(action: Action<Payload>) {
  try {
    const buyersPage: IPage<Buyer> = yield apiCallWrapper<IPage<Buyer>>(getBuyersByBirCode, action.payload)
    yield put(actionCreators.loadBuyersByBirCodeSuccess(buyersPage))
  } catch (error) {
    yield put(actionCreators.loadBuyersByBirCodeError(error))
  }
}

function* loadBuyerById(action: Action<ID>) {
  try {
    const buyer: Buyer = yield apiCallWrapper<Buyer>(getBuyerById, action.payload)
    yield put(actionCreators.loadBuyerByIdSuccess(buyer))
  } catch (error) {
    yield put(actionCreators.loadBuyerByIdError(error))
  }
}

function* updateBuyer(action: Action<DmsAccountInfo>) {
  try {
    const updatedDMSInfo: DmsAccountInfo = yield apiCallWrapper(putDmsInfo, action.payload)
    yield put(actionCreators.updateDmsInfoSuccess(updatedDMSInfo));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.BuyerUpdated"))))
  } catch (error) {
    yield put(actionCreators.updateDmsInfoError(error))
  }
}

function* updateSeller(action: Action<Seller>) {
  try {
    const updatedSellerInfo: Seller = yield apiCallWrapper(patchDmsR1, action.payload)
    yield put(actionCreators.updateSellerDmsR1Success(updatedSellerInfo));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.SellerUpdated"))))
  } catch (error) {
    yield put(actionCreators.updateSellerDmsR1Error(error))
  }
}

function* subscribeBuyerMass(action: Action<BuyerSubscribeRequest>) {
  try {
    const buyers: BuyerSubscribeResponse[] = yield apiCallWrapper(postSubscribeMass, action.payload);
    yield put(actionCreators.subscribeBuyerMassSuccess(buyers));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.SubscribeSuccess"))))
  } catch (err) {
    yield put(actionCreators.subscribeBuyerMassError())
  }
}

function* unsubscribeBuyerMass(action: Action<BuyerSubscribeRequest>) {
  try {
    const buyers: BuyerSubscribeResponse[] = yield apiCallWrapper(postUnsubscribeMass, action.payload);
    yield put(actionCreators.unsubscribeBuyerMassSuccess(buyers));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.UnsubscribeSuccess"))))
  } catch (err) {
    yield put(actionCreators.unsubscribeBuyerMassError())
  }
}

function* loadCountries() {
  try {
    const myRolePrivilege = store.getState().rp4.roles.roleData.myRolePrivilege
    const myBircode = store.getState().rp4.roles.myBIRCodeData?.bircode
    const countries: Country[] = yield apiCallWrapper(getCountries);
    if (myRolePrivilege === RolePrivilege.R1) {
      let disconnect = true;

      countries.forEach(country => {
        country.bircodeDataList.forEach(x => {
          const birCode = x.bircode;
          if (birCode === myBircode) {
            disconnect = false;
          }
        })
      })

      if (disconnect) store.dispatch(loginActions.authLogout())
    }

    yield put(actionCreators.loadCountriesSuccess(countries));
    yield put(contextActions.setAllBIRCodeDataList(countries));


  } catch (error) {
    yield put(actionCreators.loadCountriesError(error));
  }
}

export default function* () {
  yield takeLatest(Type.LOAD_SELLER_BY_BIRCODE, loadSellerByBirCode)
  yield takeLatest(Type.LOAD_BUYERS_BY_BIRCODE, loadBuyersByBirCode)
  yield takeLatest(Type.LOAD_BUYER_BY_ID, loadBuyerById)
  yield takeLatest(Type.UPDATE_BUYER, updateBuyer)
  yield takeLatest(Type.LOAD_COUNTRIES, loadCountries)
  yield takeLatest(Type.UPDATE_SELLER_DMS_R1, updateSeller)
  yield takeLatest(Type.SUBSCRIBE_BUYER_MASS, subscribeBuyerMass)
  yield takeLatest(Type.UNSUBSCRIBE_BUYER_MASS, unsubscribeBuyerMass)
}