import { Space } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonBack } from "shared/components/Buttons/ButtonBack";
import { PageContainer } from 'shared/GlobalStyles';
import { EventsTable } from "../components/EventsTable";
import { ItemsTable } from '../components/ItemsTable';
import { OrderDetailsMain } from "../components/OrderDetailsMain";
import {
  actionCreators,
  lastVisitedTabOrdersSelector,
  toggleNavigation
} from '../stores';
import { DetailsRow } from '../Styles';

import { OrderDetailsPDF } from '../components/OrderDetailsPDF';

const OrderDetails: FC = () => {
  const { ref } = useParams<{ ref: string }>();
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isOpen = useSelector(toggleNavigation);
  const lastVisitedTabOrders = useSelector(lastVisitedTabOrdersSelector);

  useEffect(() => {
    if (lastVisitedTabOrders && !isOpen) dispatch(actionCreators.toggleNavigationOpen())
  }, [lastVisitedTabOrders])

  useEffect(() => {
    dispatch(actionCreators.getByRef(ref));
  }, [dispatch, ref]);

  return (
    <PageContainer>
      <Space>
        <ButtonBack fallback={"/orders/tracking"} />
        <OrderDetailsPDF />
      </Space>

      <h4>{t("Orders.Details.OrderInfo")}</h4>
      <OrderDetailsMain />

      <h4>{t("Orders.Details.OrderDetails")}</h4>
      <DetailsRow>
        <ItemsTable />
      </DetailsRow>

      <h4>{t("Orders.Details.OrderEvents")}</h4>
      <DetailsRow>
        <EventsTable />
      </DetailsRow>

    </PageContainer>
  );
};

export default OrderDetails;
