
import { Action, handleActions } from 'redux-actions'
import { IAlertMessage, IApiError, IApiState } from './types'
import * as actions from './actions'
import { isNil } from 'lodash'

export const API_INITIAL_STATE: IApiState = {
  loading: false,
  error: undefined
}

export default handleActions<IApiState, any>(
  {
    [actions.Type.LOADING_START]: (state: IApiState) => {
        return {
        ...state,
        loading: true
      }
    },
    [actions.Type.LOADING_SUCCESS]: (
      state: IApiState, 
      action: Action<IAlertMessage | undefined>
    ) => ({
        ...state,
        loading: false,
        error: undefined,
        alert: action.payload
    }),
    [actions.Type.LOADING_FAIL]: (
      state:  IApiState,
      action: Action<IApiError>
    ) => ({
      ...state,
      loading: false,
      alert: {
        type: 'Error',
        title: action.payload.code,
        message: action.payload.message || 'Error'
      },
      error: {
        ...action.payload,
        id: isNil(state.error) ? 0 : state.error?.id + 1
      }
    }),
    [actions.Type.LOADING_CLEAR]: (state: IApiState) => ({
      ...state,
      error: undefined,
      alert: undefined
    })
  },
  API_INITIAL_STATE
)