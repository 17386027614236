import { Button, Card, Menu, Row, Space } from 'antd';
import styled, { keyframes } from 'styled-components';
import { BasicContainer } from '../../Styles';
import { WarehouseCode, WarehouseColors } from './stores';

/**
 * All Orders Styles
 */
export const DisplayContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: 33em;
  width: 100%;
`;

export const OrdersTableWrapper = styled.div`
  min-height: 340px;
  .order-table {
    pointer-events: none;
  }
`;

interface ToggleProps {
  $collapsed: boolean
}

const slideRightAnimation = keyframes`
  from {
    left: 0;
  }
  to {
    left: 152px;
  }
 `;

export const ToggleWrapper = styled.div`
  width: ${(props: ToggleProps) => !props.$collapsed ? '198.36px' : null};
  display: flex;
`;

export const ToggleButton = styled(Button)`
  width: ${(props: ToggleProps) => props.$collapsed ? '100%' : null};
  animation: ${(props: ToggleProps) => !props.$collapsed ? slideRightAnimation : null} .3s forwards 1;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  margin: 0 1%;
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThingsContainer = styled.div`
  background: #F0F2F5;
  border-radius: 2px;
  padding: .5em;
`;


export const CustomItem = styled(Menu.Item)`
  color: #000;
  // font-weight: 500;
`;

export const InfosRow = styled(Row)`
  display: flex;  
  width: 100%;
  justify-content: space-between;
  background: #F0F2F5;
  padding: .7em;
  margin: 0 !important;
`;

export const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  background: #F0F2F5;
  padding: .7em;
`;

export const DetailsCard = styled(Card)`
  width: 390px;
  .ant-card-body {
    padding: .5em;
  }
`;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Value = styled.span`
  font-weight: bold;
`;

export const Rem = styled(Value)`
  color: #8bdf4b;
`;

export const Total = styled(Space)`
  & > span.wrongPrice {
    font-size: 8px;
    font-weight: lighter;
    color: red;
    text-decoration: line-through;
  }
  & > span.finalAmount {
    font-weight: bolder;
  }
`;

export const WrongPrice = styled(Value)`
  font-size: small;
  font-weight: lighter;
  color: red;
  text-decoration: line-through;
`;

export const FinalAmount = styled(Value)`
  font-weight: bolder;
`;

export const OrderLink = styled.span`
  font-weight: bold;
  :hover {
    pointer-events: initial;
  }
`

export const StatusFilterWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 195px;
`

export const StatusFilterList = styled.div`
  display: flex;
`

export const StatusFilterInput = styled.input`
  margin: 8px;
`

export const ThingsHelperTable = styled.table`
  border: 1px solid lightgrey;
  border-collapse: collapse;
  & th, & td {
    border: 1px solid lightgrey;
    padding: 3px;
    border-collapse: collapse;
  }
`

interface StockCapsProps {
  stock: WarehouseCode
}

export const StockCaps = styled.span`
  height: 25px; 
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${(props: StockCapsProps) => WarehouseColors[props.stock]}
`

export const ItemsStocksWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`

export const ItemsStocksAvailableQuantity = styled.span`
  font-weight: bold;
  font-size: 12px; 
`