import { ColumnsType } from "antd/lib/table";
import { createAction } from "redux-actions";
import { ID, IPage, Payload } from "shared/utils/types";
import { Buyer, BuyersAction, BuyerSubscribeRequest, BuyerSubscribeResponse, Country, DmsAccountInfo, Seller } from "./types";

export enum Type {
    LOAD_SELLER_BY_BIRCODE = 'LOAD_SELLER_BY_BIRCODE',
    LOAD_SELLER_BY_BIRCODE_SUCCESS = 'LOAD_SELLER_BY_BIRCODE_SUCCESS',
    LOAD_SELLER_BY_BIRCODE_ERROR = "LOAD_SELLER_BY_BIRCODE_ERROR",
    LOAD_BUYERS_BY_BIRCODE = 'LOAD_BUYERS_BY_BIRCODE',
    LOAD_BUYERS_BY_BIRCODE_SUCCESS = 'LOAD_BUYERS_BY_BIRCODE_SUCCESS',
    LOAD_BUYERS_BY_BIRCODE_ERROR = 'LOAD_BUYERS_BY_BIRCODE_ERROR',
    LOAD_BUYER_BY_ID = 'LOAD_BUYER_BY_ID',
    LOAD_BUYER_BY_ID_SUCCESS = 'LOAD_BUYER_BY_ID_SUCCESS',
    LOAD_BUYER_BY_ID_ERROR = 'LOAD_BUYER_BY_ID_ERROR',
    UPDATE_BUYER = 'UPDATE_DMS_INFO',
    UPDATE_DMS_INFO_SUCCESS = 'UPDATE_DMS_INFO_SUCCESS',
    UPDATE_DMS_INFO_ERROR = 'UPDATE_DMS_INFO_ERROR',
    UPDATE_SELLER_DMS_R1 = 'UPDATE_SELLER_DMS_R1',
    UPDATE_SELLER_DMS_R1_SUCCESS = 'UPDATE_SELLER_DMS_R1_SUCCESS',
    UPDATE_SELLER_DMS_R1_ERROR = 'UPDATE_SELLER_DMS_R1_ERROR',
    TOOGLE_NAVIGATION_MENU = 'TOOGLE_NAVIGATION_MENU',
    SUBSCRIBE_BUYER_MASS = 'SUBSCRIBE_BUYER_MASS',
    SUBSCRIBE_BUYER_MASS_SUCCESS = 'SUBSCRIBE_BUYER_MASS_SUCCESS',
    SUBSCRIBE_BUYER_MASS_ERROR = 'SUBSCRIBE_BUYER_MASS_ERROR',
    UNSUBSCRIBE_BUYER_MASS = 'UNSUBSCRIBE_BUYER_MASS',
    UNSUBSCRIBE_BUYER_MASS_SUCCESS = 'UNSUBSCRIBE_BUYER_MASS_SUCCESS',
    UNSUBSCRIBE_BUYER_MASS_ERROR = 'UNSUBSCRIBE_BUYER_MASS_ERROR',
    SET_BUYER_COLUMNS = 'SET_BUYER_COLUMNS',
    SET_BUYER_PAYLOAD = 'SET_BUYER_PAYLOAD',
    SET_BUYER_LAST_VISITED_TAB = 'SET_BUYER_LAST_VISITED_TAB',
    SET_BUYER_SELECTED_ROWS = 'SET_BUYER_SELECTED_ROWS',
    SET_BUYER_ACTION_TYPE = "SET_BUYER_ACTION_TYPE",
    LOAD_COUNTRIES = 'LOAD_COUNTRIES',
    LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS',
    LOAD_COUNTRIES_ERRORS = 'LOAD_COUNTRIES_ERRORS',
}

export const actionCreators = {
    loadCountries: createAction(Type.LOAD_COUNTRIES),
    loadCountriesSuccess: createAction<Array<Country>, Array<Country>>(Type.LOAD_COUNTRIES_SUCCESS, (payload: Array<Country>) => payload),
    loadCountriesError: createAction(Type.LOAD_COUNTRIES_ERRORS),
    loadSellerByBirCode: createAction(Type.LOAD_SELLER_BY_BIRCODE),
    loadSellerByBirCodeSuccess: createAction<Seller, Seller>(Type.LOAD_SELLER_BY_BIRCODE_SUCCESS, (seller: Seller) => seller),
    loadSellerByBirCodeError: createAction(Type.LOAD_SELLER_BY_BIRCODE_ERROR),
    loadBuyersByBirCode: createAction<Payload, Payload>(Type.LOAD_BUYERS_BY_BIRCODE, (payload: Payload) => payload),
    loadBuyersByBirCodeSuccess: createAction<IPage<Buyer>, IPage<Buyer>>(Type.LOAD_BUYERS_BY_BIRCODE_SUCCESS, (buyers: IPage<Buyer>) => buyers),
    loadBuyersByBirCodeError: createAction(Type.LOAD_BUYERS_BY_BIRCODE_ERROR),
    loadBuyerById: createAction<ID, ID>(Type.LOAD_BUYER_BY_ID, (payload: ID) => payload),
    loadBuyerByIdSuccess: createAction<Buyer, Buyer>(Type.LOAD_BUYER_BY_ID_SUCCESS, (payload: Buyer) => payload),
    loadBuyerByIdError: createAction(Type.LOAD_BUYER_BY_ID_ERROR),
    updateDmsInfo: createAction<DmsAccountInfo, DmsAccountInfo>(Type.UPDATE_BUYER, (payload: DmsAccountInfo) => payload),
    updateDmsInfoSuccess: createAction<DmsAccountInfo, DmsAccountInfo>(Type.UPDATE_DMS_INFO_SUCCESS, (payload: DmsAccountInfo) => payload),
    updateDmsInfoError: createAction(Type.UPDATE_DMS_INFO_ERROR),
    updateSellerDmsR1: createAction<Seller, Seller>(Type.UPDATE_SELLER_DMS_R1, (payload: Seller) => payload),
    updateSellerDmsR1Success: createAction<Seller, Seller>(Type.UPDATE_SELLER_DMS_R1_SUCCESS, (payload: Seller) => payload),
    updateSellerDmsR1Error: createAction(Type.UPDATE_SELLER_DMS_R1_ERROR),
    toggleNavigationOpen: createAction(Type.TOOGLE_NAVIGATION_MENU),
    subscribeBuyerMass: createAction<BuyerSubscribeRequest, BuyerSubscribeRequest>(Type.SUBSCRIBE_BUYER_MASS, (payload: BuyerSubscribeRequest) => payload),
    subscribeBuyerMassSuccess: createAction<BuyerSubscribeResponse[], BuyerSubscribeResponse[]>(Type.SUBSCRIBE_BUYER_MASS_SUCCESS, (payload: BuyerSubscribeResponse[]) => payload),
    subscribeBuyerMassError: createAction(Type.SUBSCRIBE_BUYER_MASS_ERROR),
    unsubscribeBuyerMass: createAction<BuyerSubscribeRequest, BuyerSubscribeRequest>(Type.UNSUBSCRIBE_BUYER_MASS, (payload: BuyerSubscribeRequest) => payload),
    unsubscribeBuyerMassSuccess: createAction<BuyerSubscribeResponse[], BuyerSubscribeResponse[]>(Type.UNSUBSCRIBE_BUYER_MASS_SUCCESS, (payload: BuyerSubscribeResponse[]) => payload),
    unsubscribeBuyerMassError: createAction(Type.UNSUBSCRIBE_BUYER_MASS_ERROR),
    setBuyerPayload: createAction<Payload, Payload>(Type.SET_BUYER_PAYLOAD, (payload: Payload) => payload),
    setBuyersColumns: createAction<ColumnsType<Buyer>, ColumnsType<Buyer>>(Type.SET_BUYER_COLUMNS, (payload: ColumnsType<Buyer>) => payload),
    setBuyersLastVisitedTab: createAction<string, string>(Type.SET_BUYER_LAST_VISITED_TAB, (payload: string) => payload),
    setBuyersSelectedRows: createAction<Buyer[], Buyer[]>(Type.SET_BUYER_SELECTED_ROWS, (payload: Buyer[]) => payload),
    setBuyersActionType: createAction<BuyersAction, BuyersAction>(Type.SET_BUYER_ACTION_TYPE, (payload: BuyersAction) => payload)
}