import { FC } from 'react';
import { Card, Col, Table } from 'antd';
import { useSelector } from 'react-redux';
import { sellerLoadingSelector, AccountClientCode } from '../stores';
import moment from 'moment';
import { useBuyersClientCodesColumns } from '../hooks/useBuyersClientCodesColumns';

interface BuyersClientCodesTableProps {
  accountClientCodes: AccountClientCode[]
}

export const BuyersClientCodesTable: FC<BuyersClientCodesTableProps> = ({ accountClientCodes }) => {
  const loading = useSelector(sellerLoadingSelector);

  const columns = useBuyersClientCodesColumns();

  const dataSource = accountClientCodes.filter(item => {
    const now = moment();
    const validitystartdate = moment(item?.validitystartdate);
    const validityenddate = moment(item?.validityenddate);

    return ((now >= validitystartdate) && (now <= validityenddate)) || (!item?.validityenddate)
  });

  return (
    <>
      {dataSource && <Col span={24}>
        <Card bordered={false}>
          <Table
            size="small"
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Card>
      </Col>}
    </>
  );
};

