import { FC } from "react";
import { actionCreators, Order, orderByRefDataSelector, OrderStatus } from '../stores';
import { DetailsCard } from '../Styles';
import { Col } from 'antd';
import { Field, ActionButton } from "../Styles"
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { API_BDD } from "shared/utils/constants";

export const OrderStatusAction: FC = () => {

    const ORDER_STATUS_VALIDATION = API_BDD === "oracle" ? OrderStatus.DMSTreatment : OrderStatus.WaitToBeSent;
    const ORDER_STATUS_REJECTION = OrderStatus.R1Refuse;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const order = useSelector(orderByRefDataSelector)

    const HandleChangeStatus = (newStatus: number) => {
        if (order) {
            const tempOrder: Order = {
                ...order,
                status: newStatus,
            };

            dispatch(actionCreators.updateOrder(tempOrder));
        }
    };

    const HandleValidate = () => HandleChangeStatus(ORDER_STATUS_VALIDATION)
    const HandleReject = () => HandleChangeStatus(ORDER_STATUS_REJECTION)
    const HandleDMSReinit = () => order && dispatch(actionCreators.retransmissionToDMS(order.id));

    const status = typeof order?.status === "string" ? parseInt(order.status) : order?.status

    return (<>
        {
            status === OrderStatus.ValidationRequired && (
                <Col>
                    <DetailsCard>
                        <Field>
                            <ActionButton
                                type="primary"
                                onClick={HandleValidate}
                            >
                                {t("Generic.Actions.Validate")}
                            </ActionButton>

                            <ActionButton
                                type="default"
                                onClick={HandleReject}
                            >
                                {t("Generic.Actions.Reject")}
                            </ActionButton>
                        </Field>
                    </DetailsCard>
                </Col>
            )
        }

        {
            order?.needReemissionDMS && (
                <Col>
                    <DetailsCard>
                        <Field>
                            <ActionButton
                                type="primary"
                                onClick={HandleDMSReinit}
                            >
                                {t("Orders.Others.ReissueDMS")}
                            </ActionButton>

                            <ActionButton
                                type="default"
                                onClick={HandleReject}
                            >
                                {t("Generic.Actions.Reject")}
                            </ActionButton>
                        </Field>
                    </DetailsCard>
                </Col>
            )
        }
    </>

    )
}