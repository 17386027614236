import { Button, Card, DatePicker, Form, InputNumber, Select, TimePicker } from "antd";
import moment from "moment";
import { Dispatch, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorLogsInterfaceId } from "../hooks/useErrorLogsInterfaceId";
import { ErrorLogsRequestPayload } from "../stores";

interface ErrorLogsFormProps {
    onValidation: Function
    payload: ErrorLogsRequestPayload
    setPayload: Dispatch<ErrorLogsRequestPayload>
}

export const ErrorLogsForm: FC<ErrorLogsFormProps> = ({ onValidation, payload, setPayload }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const interfaceIdList = useErrorLogsInterfaceId();
    const [fileCreatedDate, setFileCreatedDate] = useState<any>();

    const handleOnFinish = (values: any) => {
        Object.keys(values).forEach(key => {
            const value = values[key];
            if (!value) {
                delete values[key]
                delete payload[key]
            }
            if (key === 'fileCreatedDate' && value) {
                let fileCreatedDate = moment(value).format("MM/DD/YYYY")
                fileCreatedDate =
                    `${fileCreatedDate} ${values["fileCreatedDateHours"] ?
                        moment(values["fileCreatedDateHours"]).format("LTS")
                        :
                        "00:00:00"
                    }`;
                values[key] = fileCreatedDate;
                delete values["fileCreatedDateHours"];
            }
        })
        setPayload({
            ...payload,
            ...values
        })
        onValidation()
    }

    return <Card style={{ margin: "15px 0px" }}>
        <Form
            form={form}
            onFinish={handleOnFinish}
            layout="inline"
        >
            <Form.Item
                rules={[{ required: true }]}
                label={t("ErrorLogs.Columns.interfaceId")} name="interfaceId">
                <Select style={{ minWidth: "150px" }} allowClear={true}>
                    {interfaceIdList.map(x => <Select.Option value={x}>{x}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label={t("ErrorLogs.Columns.fileLotNumber")} name="fileLotNumber">
                <InputNumber />
            </Form.Item>
            <Form.Item
                label={t("Generic.Others.Date")} name="fileCreatedDate">
                <DatePicker style={{ minWidth: "150px" }} onChange={(value) => { setFileCreatedDate(value) }} />
            </Form.Item>
            <Form.Item label={t("Generic.Others.Hour")} name="fileCreatedDateHours">
                <TimePicker disabled={!fileCreatedDate} style={{ minWidth: "150px" }} />
            </Form.Item>
            <Form.Item label={t("ErrorLogs.Columns.fileStatus")} name="fileStatus">
                <InputNumber />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">{t("Generic.Actions.Validate")}</Button>
            </Form.Item>
        </Form>
    </Card>
}