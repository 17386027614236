import { ColumnsType } from "antd/lib/table";
import { BircodeData } from "screens/context/stores";
import { ID, IPage, Payload } from "shared/utils/types";

export interface Country {
  countryid: string
  countrycode: string
  currencycode: string
  currencysymbol: string
  urgentflagfeature: boolean
  urgentflagdefault: boolean
  countrylogistic: string
  timezone: string
  active: boolean
  bircodeDataList: BircodeData[]
}

export interface Seller {
  accountData: AccountData
  openingTime: OpeningTime
  accountDmsR1: AccountDmsR1
  garageData: GarageData
}

export interface GarageData {
  addressLine: string
  birCode: string,
  city: string,
  classification: string
  commercialName: string
  country: string
  email: string
  establishmentNumber: string
  fax: string
  foundIn: string
  phone: string
  postalCode: string
  prCode: string
  tresorCode: string
}

export interface Buyer {
  id: ID
  pomversion: number
  garageid: ID
  businessactivitybodyshop: string
  businessactivitymechanics: string
  emailaddress: string
  businessname: string
  codepr: string
  organizationname: string
  profemailadd: string
  address1: string
  address2: string
  address3: string
  city: string
  zipcode: string
  phonenbr: string
  faxnbr: string
  cvprhonenbr: string
  establishmentnum: string
  contactfirstname: string
  contactlastname: string
  phone: string
  fax: string
  mail: string
  garagepresentationmessage: string
  isr1: string
  state: string
  languagecode: string
  garagelocalnbr: string
  bircode: string
  bir3msitenbr: string
  tresorcode: string
  activityenddate: Date
  businessclassification: string
  capitaltype: string
  capitalgarageid: string
  catalogflag: string
  type: string
  alertemail: string
  clienttype: string
  accountbuyers: AccountData[]
  dmsAccountInfos: DmsAccountInfo[]
  accountClientCodes: AccountClientCode[]
}

export interface BuyerSubscribeRequest {
  buyers: ID[]
}

export interface BuyerSubscribeResponse {
  id: ID
  validityStartDate: Date | string
  validityEndDate: Date | string
  statusCode: number
  statusMessage: string
}

export interface AccountData {
  id: ID
  garageId: ID
  commercialName: string
  organisationName: string
  creationDate: Date | string
  modificationDate: Date | string
  establishmentNumber: string
  birCode: string
  tresorCode: string
  accountAddrs: AccountAddr[]
  dmsR1Users: DmsR1User[]
  validationStartDate: Date | string
  validationEndDate: Date | string
  subscribeAllowed: boolean
  unsubscribeAllowed: boolean
}

interface OpeningTime {
  weekdayOpenHour: string
  weekdayClosedHour: string
  saturdayOpenHour: string
  saturdayClosedHour: string
  sundayOpenHour: string
  sundayClosedHour: string
}

interface AccountDmsR1 {
  dmsstocksstate: string
  dmscatalogstate: string
  dmspricestate: string
  dmscustomerproductdiscstate: string
  dmscustomergroupdiscstate: string
  dmspersonnalratestate: string
  dmsfinancialstatusstate: string
  regionalstock: string
  dmstype: string
  dmsonlineflag: string
  dmsflux: string
  wholesalerLogistic: boolean
  directLogistic: boolean
}

interface DmsR1User {
  id: number
  lastname: string
  firstname: string
  phone: string
  email: string
  receivemailonevent: string
}

interface AccountAddr {
  id: number
  creationDate: Date | string
  lastupdateDate: Date | string
  address1: string
  address2: string
  address3: string
  address4: string
  city: string
  zipcode: string
  state: string
  phone: string
  fax: string
  mail: string
  addressType: string
  principal: string
}

export interface DmsAccountInfo {
  actualinvoiceamount?: string
  dmsaccountinfoid: string
  automaticordertransfer: string // boolean
  deliverymethod: string
  managecreditlimit: string // boolean
  maxinvoiceamount: string
  maxorderamount: string
  r1Id: number
  r2Id: number
}

export interface AccountClientCode {
  clientcode: string
  creationdate: string
  id: number
  lastupdatedate: string
  principal: boolean
  validityenddate: string
  validitystartdate: string
}

export enum BuyersAction {
  SUBSCRIBE,
  UNSUBSCRIBE
}

export interface AccountsState {
  showNavigation: boolean

  seller: {
    data?: Seller
    loading: boolean
  }

  buyers: {
    data?: IPage<Buyer>,
    loading: boolean
    payload: Payload,
    columns?: ColumnsType<Buyer>,
    lastVisitedTab?: string

    payloadById?: number
    dataById?: Buyer
    loadingById: boolean

    selectedRows: Buyer[]
    buyersMassActionType: BuyersAction
  }

  countries: {
    data?: Country[]
    loading: boolean
  }
}