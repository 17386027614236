
import OrdersTracking from './pages/OrdersTracking';
import OrderDetails from './pages/OrderDetails';
import { Route } from 'shared/utils/types';

/**
 * Nested routes of Orders Module
 */
export const OrdersRoutes: Route[] = [
  {
    name: "tracking",
    path: "/tracking",
    component: OrdersTracking,
    isProtected: true,
    isBircodeRequired: true
  },
  {
    name: "details",
    path: "/:ref",
    component: OrderDetails,
    isProtected: true
  }
];
