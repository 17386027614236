import { Button, Spin } from 'antd';
import styled from 'styled-components';

export const PopupContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const RolesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;


export const SelectWrapper = styled.div`
    margin-bottom: 15px;
    display: flex;
    flex-direction:column
`;

export const SelectLabel = styled.p`
  
`

export const CustomButton = styled(Button)`
    width: 50%;
    background-color: rgb(0 0 0);
    color: #e1e1e1;
    border: none;
    :hover {
        color: #fff;
        text-decoration: none!important;
        background-color: rgba(255, 204, 51, 0.7);
    }
`;

export const HeaderToggleButton = styled(Button)`
  background-color: #333333 !important;
  color: #e1e1e1 !important;
  border: none;
  :hover {
    color: #FC3 !important;
    text-decoration: none !important;
    background-color: #333333;
  }
`;

export const SelectedBircode = styled.div`
  margin-bottom: 15px;
`;

export const ContextSpinner = styled(Spin)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 122px;
`;
