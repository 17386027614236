import { UserManager, WebStorageStateStore } from 'oidc-client'
import { RpUser } from 'screens/login/stores/types'

const settings = {
  authority: process.env.REACT_APP_IDP_URI,
  client_id: process.env.REACT_APP_CLIENT_ID,
  response_type: "code",
  scope: process.env.SCOPE,
  redirect_uri: `${window.location.origin}/1ss-Oidc-Callback`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  silent_redirect_uri: `${window.location.origin}/1ss-Oidc-Callback-Silent`,
  userStore: new WebStorageStateStore({ store: localStorage })
}

const userManager = new UserManager(settings)

export const pkceRenewToken = () => {
  return userManager.signinSilent() as Promise<RpUser>
}

export const pkceLogin = () => {
  return userManager.signinPopup() as Promise<RpUser>
}

export const pkceLogOut = () => {
  return userManager.signoutRedirect()
}
