import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { buyersSelectedRowsSelector, buyersMassActionTypeSelector, BuyersAction, actionCreators } from "../stores";
import { Presentation } from "shared/components/Modal/Styles";
import { Select, Space } from "antd";

const { Option } = Select;

export const BuyersTableMassActionModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const buyersSelectedRows = useSelector(buyersSelectedRowsSelector);
    const buyersMassActionType = useSelector(buyersMassActionTypeSelector);

    const handleOnChange = (value: BuyersAction) =>
        dispatch(actionCreators.setBuyersActionType(value));

    return <Presentation>
        <Space direction="vertical">
            <Select onChange={handleOnChange} defaultValue={buyersMassActionType}>
                <Option value={BuyersAction.SUBSCRIBE}>
                    {t("Generic.Actions.Subscribe")}
                </Option>
                <Option value={BuyersAction.UNSUBSCRIBE}>
                    {t("Generic.Actions.Unsubscribe")}
                </Option>
            </Select>
            {
                buyersSelectedRows.map(buyer => buyer.businessname)
            }
        </Space>
    </Presentation>
}