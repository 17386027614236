import { api, API_STOCK_EXCHANGE_SERVICE, getArgs, unwrapAxiosResult } from 'shared'
import { ID, IPage } from 'shared/utils/types'
import { StockExchangeRequestPayload, StockExchange, StockExchangeLnkRequestPayload, StockExchangeLnk } from './types'

const STOCK_EXCHANGE_URI = `${API_STOCK_EXCHANGE_SERVICE}/stock-exchanges`

export const getStockExchange = (payload: StockExchangeRequestPayload) =>
    unwrapAxiosResult<IPage<StockExchange>>(api.get<IPage<StockExchange>>(
        `${STOCK_EXCHANGE_URI}?${getArgs(payload)}`
    ))

export const getStockExchangeLnk = (ref: ID, payload: StockExchangeLnkRequestPayload) => {
    delete payload.ref
    return unwrapAxiosResult<IPage<StockExchangeLnk>>(api.get<IPage<StockExchangeLnk>>(
        `${STOCK_EXCHANGE_URI}/lnk/${ref}?${getArgs(payload)}`
    ))
}