import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCountry } from 'screens/accounts/hooks/useCountry';
import { useUrgentCountriesList } from 'screens/accounts/hooks/useUrgentCountriesList';
import { API_BDD, handleTableChange } from 'shared';
import { Payload } from 'shared/utils/types';
import {
  actionCreators, Order, orderLoadingSelector, orderPageSelector, ordersSelectedRowsSelector
} from '../stores';
import { OrdersTableWrapper } from '../Styles';
import { OrderTableMassAction } from './OrderTableMassAction';

interface OrdersTableProps {
  columns: ColumnsType<Order>
  setColumns: Dispatch<ColumnsType<Order>>
  payload: Payload
  setPayload: Dispatch<Payload>
}

export const OrdersTable: FC<OrdersTableProps> = ({ payload, setPayload, columns, setColumns }) => {
  const loading = useSelector(orderLoadingSelector);
  const currentOrders = useSelector(orderPageSelector);
  const ordersSelectedRows = useSelector(ordersSelectedRowsSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    onChange: (_: any, selectedRows: any) => {
      dispatch(actionCreators.setSelectedOrders(selectedRows));
    },
    selectedRowKeys: ordersSelectedRows.map((o: Order) => o.id)
  };

  const data = currentOrders?.content.map((item: Order) => {
    let res: Order = {
      ...item,
      key: (item.id),
      chosen: true
    };

    if (API_BDD === "oracle" && res?.needReemissionDMS) {
      let orderEventIsLate = true;
      res = {
        ...res,
        orderEventIsLate
      }
    }

    return res
  });

  const pagination: TablePaginationConfig = {
    showSizeChanger: true,
    pageSize: currentOrders?.size,
    total: currentOrders?.totalElements,
  };

  if (payload && payload.page) {
    pagination.defaultCurrent = payload.page + 1
  }

  const rowClassName = (record: Order) =>
    `clickable ${record.orderEventIsLate ? " orderEventIsLate" : ""}`

  const [finalColumns, setFinalsColumns] = useState(columns);

  const country = useCountry()
  const urgentCountriesList = useUrgentCountriesList();

  useEffect(() => {
    if (country && !urgentCountriesList.includes(country.countrycode)) {
      setFinalsColumns(columns.filter((column: any) => column.dataIndex !== 'urgentFlag'))
    } else if (country && columns.filter((column: any) => column.dataIndex === 'urgentFlag').length === 0) {
      const tempColumns = columns
      tempColumns.splice(3, 0, {
        title: t("Orders.Columns.urgentFlag"),
        dataIndex: 'urgentFlag',
        key: 'urgentFlag',
        render: (_: any, record: Order) => record.urgentFlag ?
          <CheckCircleTwoTone /> :
          <CloseCircleTwoTone twoToneColor="#eb2f96" />
      })
      setFinalsColumns(tempColumns)
    }
  }, [country])

  return (
    <OrdersTableWrapper>
      <Table
        size="middle"
        loading={loading}
        rowSelection={rowSelection}
        pagination={pagination}
        dataSource={data}
        columns={finalColumns}
        onChange={handleTableChange(payload, setPayload, finalColumns, setColumns)}
        rowClassName={rowClassName}
      />
      <OrderTableMassAction />
    </OrdersTableWrapper>
  );
};
