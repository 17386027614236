import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { sellerDataSelector, sellerLoadingSelector } from "../stores";
import { CustomButton } from '../Styles';
import { useSellerForm } from "../hooks/useSellerForm";
import { CenteredSpin } from "shared/components/Styles";
import { SellerFormOpeningTime } from "./SellerFormOpeningTime";
import { SellerFormDmsInfo } from "./SellerFormDmsInfo";
import { FormWrapper } from "shared/components/Form/FormWrapper";
import { DetailsRow } from "screens/orders/Styles";

export const SellerForm: FC = () => {
    const { t } = useTranslation()
    const sellerForm = useSellerForm()

    const seller = useSelector(sellerDataSelector);
    const loading = useSelector(sellerLoadingSelector)
    if (loading || !seller || !sellerForm.onFinish) return <CenteredSpin size="large" />

    return (
        <DetailsRow>
            <FormWrapper {...sellerForm}>
                <SellerFormOpeningTime />
                <SellerFormDmsInfo />
                <CustomButton type="primary" htmlType="submit">{t("Generic.Actions.Validate")}</CustomButton>
            </FormWrapper>
        </DetailsRow>
    );
}