import { Login } from 'screens/login/Login';
import { OidcCallback } from 'screens/oidc/OidcCallback';
import { OidcCallbackSilent } from 'screens/oidc/OidcCallbackSilent';
import { Home } from 'screens/home/Home';
import Orders from 'screens/orders/Orders';
import { OrdersRoutes } from 'screens/orders/OrdersRoutes';
import { Accounts } from 'screens/accounts/Accounts';
import { NotFound } from 'screens/notFound/NotFound';
import { ErrorLogsRoutes } from 'screens/errorLogs/ErrorLogsRoutes';
import ErrorLogs from 'screens/errorLogs/ErrorLogs';
import { AccountsRoutes } from 'screens/accounts/navigation/AccountsRoutes';
import store from 'stores/store';
import { RolePrivilege } from 'screens/context/stores';
import { Route } from 'shared/utils/types';
import { StockExchangeRoutes } from 'screens/stockExchange/StockExchangeRoutes';
import StockExchange from "screens/stockExchange/StockExchange"

const routes: Route[] = [
  {
    name: "login",
    path: "/login",
    component: Login,
    isProtected: false,
    isBircodeRequired: false
  },
  {
    name: "1ss-Oidc-Callback",
    path: "/1ss-Oidc-Callback",
    component: OidcCallback,
    isProtected: false,
    isBircodeRequired: false
  },
  {
    name: "1ss-Oidc-Callback-Silent",
    path: "/1ss-Oidc-Callback-Silent",
    component: OidcCallbackSilent,
    isProtected: false,
    isBircodeRequired: false
  },
  {
    name: "home",
    path: "/home",
    component: Home,
    isProtected: true,
    isBircodeRequired: false
  },
  {
    name: "accounts",
    path: "/accounts",
    component: Accounts,
    isProtected: true,
    routes: AccountsRoutes,
    isBircodeRequired: true
  },
  {
    name: "orders",
    path: "/orders",
    component: Orders,
    isProtected: true,
    routes: OrdersRoutes,
  },
  {
    name: "errorLogs",
    path: "/error-logs",
    component: ErrorLogs,
    isProtected: true,
    routes: ErrorLogsRoutes,
    isBircodeRequired: true
  },
  {
    name: "stockExchange",
    path: "/stock-exchange",
    component: StockExchange,
    isProtected: true,
    routes: StockExchangeRoutes,
    isBircodeRequired: false
  },
  {
    name: "404",
    path: "*",
    component: NotFound,
    isProtected: true,
    isBircodeRequired: false
  }
];

const compile = (parentRoute: Route, subRoutes: Route[]): Route[] => {
  return subRoutes.flatMap((subRoute) => {
    let newRoute: Route = {
      name: subRoute.name,
      path: parentRoute.path + subRoute.path,
      component: subRoute.component,
      isProtected: subRoute.isProtected,
      isBircodeRequired: subRoute.isBircodeRequired
    };
    if (subRoute.routes) {
      newRoute.routes = [...compile(newRoute, subRoute.routes)];
    }
    return newRoute;
  });
};

export const getRoutes = () => {
  const parentRoute = {
    name: '',
    path: ''
  };


  let finalRoutes = compile(parentRoute, routes)

  const rolePrivilege = store.getState().rp4.roles.roleData.myRolePrivilege;

  if (rolePrivilege && rolePrivilege <= RolePrivilege.COUNTRY_SUBSCRIPTION) {
    finalRoutes.filter((route: Route) => route.name !== "orders")
  }


  return finalRoutes
};