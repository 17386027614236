import { createAction } from 'redux-actions';
import { IAlertType } from './types';

export enum Type {
  ALERT_MSG = 'ALERT_MSG',
  ALERT_MSG_DISPLAY = 'ALERT_MSG_DISPLAY',
  ALERT_MSG_PENDING = 'ALERT_MSG_PENDING',
  ALERT_MSG_FAILED = 'ALERT_MSG_FAILED',
  ALERT_MSG_CLEAR = 'ALERT_MSG_CLEAR'
}

export const actionCreators = {
  alertMsg: createAction<IAlertType, IAlertType>(Type.ALERT_MSG, (alert: IAlertType) => alert),
  alertDisplay: createAction<boolean, boolean>(Type.ALERT_MSG_DISPLAY, (displayed: boolean) => displayed),
  alertPending: createAction<IAlertType, IAlertType>(Type.ALERT_MSG_PENDING, (alertInPending: IAlertType) => alertInPending),
  alertFailed: createAction(Type.ALERT_MSG_FAILED),
  alertClear: createAction(Type.ALERT_MSG_CLEAR)
}