import { Dispatch, FC } from 'react';
import { Table, TablePaginationConfig, Tabs } from 'antd';
import {
  actionCreators,
  Buyer,
  buyersSelector,
} from '../stores';
import { useDispatch, useSelector } from 'react-redux';
import { handleTableChange } from 'shared';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Payload } from 'shared/utils/types';
import { OrderTableMassAction } from './BuyersTableMassAction';

const { TabPane } = Tabs;

interface BuyersTableProps {
  columns: ColumnsType<Buyer>
  setColumns: Dispatch<ColumnsType<Buyer>>
  payload: Payload
  setPayload: Dispatch<Payload>
  tabBarExtraContent: JSX.Element
}

/**
 * Table to displays Account Sellers Commercial Links
 */
export const BuyersTable: FC<BuyersTableProps> = ({ columns, setColumns, payload, setPayload, tabBarExtraContent }) => {
  const { t } = useTranslation()
  const buyersState = useSelector(buyersSelector)
  const { data, loading, selectedRows: selectedBuyers } = buyersState
  const dispatch = useDispatch()

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      dispatch(actionCreators.setBuyersSelectedRows(selectedRows));
    },
    selectedRowKeys: selectedBuyers.map((o: Buyer) => o.id)
  };

  const pageSizeOptions = () => {
    if (data && data.totalElements < 10) return [data.totalElements.toString()]
    if (data && data.totalElements < 20) return ['10', data.totalElements.toString()]
    if (data && data.totalElements < 50) return ['10', '20', data.totalElements.toString()]
    if (data && data.totalElements < 100) return ['10', '20', '50', data.totalElements.toString()]
    if (data && data.totalElements) return ['10', '20', '50', '100', data.totalElements.toString()]
    return ['10', '20', '50', '100'];
  }

  const pagination: TablePaginationConfig = {
    showTotal: (total) => `Total : ${total}`,
    showSizeChanger: true,
    pageSize: data?.size,
    total: data?.totalElements,
    current: data?.number ? data.number + 1 : undefined,
    pageSizeOptions: pageSizeOptions(),
  }

  return (
    <Tabs defaultActiveKey="1" tabBarExtraContent={tabBarExtraContent}>
      <TabPane tab={t("Account.Navigation.Buyers")} key="1">
        <Table
          rowSelection={rowSelection}
          rowKey="id"
          size="middle"
          loading={loading}
          columns={columns}
          dataSource={data && data.content ? [...data.content] : []}
          rowClassName={"clickable"}
          pagination={pagination}
          onChange={handleTableChange(payload, setPayload, columns, setColumns)}
        />
        <OrderTableMassAction />
      </TabPane>
    </Tabs>
  );
};

