import { createAction } from "redux-actions";
import { Country } from "screens/accounts/stores";
import { BircodeData, RoleData } from ".";

export enum Type {
    SET_MY_BIRCODE_DATA = 'SET_MY_BIRCODE_DATA',
    SET_TO_EMPTY_MY_BIRCODE_DATA = 'SET_TO_EMPTY_MY_BIRCODE_DATA',
    SET_MY_ROLE_DATA = "SET_MY_ROLE_DATA",
    SET_GARAGE_POPOVER_VISIBLE = "SET_GARAGE_POPOVER_VISIBLE",
    SET_GARAGE_POPOVER_VALUE = "SET_GARAGE_POPOVER_VALUE",
    SET_ORDER_POPOVER_VISIBLE = "SET_ORDER_POPOVER_VISIBLE",
    SET_ORDER_POPOVER_VALUE = "SET_ORDER_POPOVER_VALUE",
    SET_ORDER_POPOVER_MSG = "SET_ORDER_POPOVER_MSG",
    SET_ALL_BIRCODE_DATA_LIST = "SET_ALL_BIRCODE_DATA_LIST",
}

export const actionCreators = {
    setMyBirCode: createAction<BircodeData, BircodeData>(Type.SET_MY_BIRCODE_DATA, (payload: BircodeData) => payload),
    setToEmptyMyBirCode: createAction(Type.SET_TO_EMPTY_MY_BIRCODE_DATA),
    setMyRole: createAction<RoleData, RoleData>(Type.SET_MY_ROLE_DATA, (payload: RoleData) => payload),
    setGaragePopoverVisible: createAction<boolean, boolean>(Type.SET_GARAGE_POPOVER_VISIBLE, (payload: boolean) => payload),
    setGaragePopoverValue: createAction<string, string>(Type.SET_GARAGE_POPOVER_VALUE, (payload: string) => payload),
    setOrderPopoverVisible: createAction<boolean, boolean>(Type.SET_ORDER_POPOVER_VISIBLE, (payload: boolean) => payload),
    setOrderPopoverValue: createAction<string, string>(Type.SET_ORDER_POPOVER_VALUE, (payload: string) => payload),
    setOrderPopoverMsg: createAction<string, string>(Type.SET_ORDER_POPOVER_MSG, (payload: string) => payload),
    setAllBIRCodeDataList: createAction<Country[], Country[]>(Type.SET_ALL_BIRCODE_DATA_LIST, (payload: Country[]) => payload),
}