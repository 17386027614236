import { Button, Space, Tabs } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredSpin } from 'shared/components/Styles';
import { PageContainer } from 'shared/GlobalStyles';
import { Payload } from 'shared/utils/types';
import { useConfigTranslate } from 'shared/hooks/useConfigTranslate';
import { BuyersTable } from '../components/BuyersTable';
import { SellerTable } from '../components/SellerTable';
import { useBuyersColumns } from '../hooks/useBuyersColumns';
import { actionCreators, buyersSelector, defaultBuyerPayload, sellerDataSelector } from '../stores';
import { AccountsTableWrapper } from '../Styles';
import { myBIRCodeDataSelector } from 'screens/context/stores/selectors';
import { useHistory } from 'react-router';

const { TabPane } = Tabs;

/**
 * Accounts R1
 */
export const AccountsTracking: FC = () => {
  let history = useHistory();

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const BuyersColumns = useBuyersColumns();

  const myBIRCodeData = useSelector(myBIRCodeDataSelector);
  const sellerData = useSelector(sellerDataSelector);
  const buyersState = useSelector(buyersSelector)
  const { columns, payload, lastVisitedTab } = buyersState

  const [buyersColumns, setBuyersColumns] = useState(lastVisitedTab && columns ? columns : BuyersColumns)
  const [buyersPayload, setBuyersPayload] = useState<Payload>(lastVisitedTab ? payload : defaultBuyerPayload)
  const [curActiveKey, setCurActiveKey] = useState<string>(lastVisitedTab ? lastVisitedTab : "1")
  const [tableKey, setTableKey] = useState<number>(0);

  const defaultActiveKey = lastVisitedTab ? lastVisitedTab : "1"

  const reinitTab = () => {
    setBuyersColumns(BuyersColumns)
    setBuyersPayload(defaultBuyerPayload)
  }

  const handleReinitFilters = () => {
    reinitTab()
    setTableKey(tableKey + 1)
  }

  const onChange = (activeKey: string) => {
    reinitTab()
    setCurActiveKey(activeKey);
  }

  useEffect(() => { dispatch(actionCreators.setBuyersColumns(buyersColumns)) }, [buyersColumns])
  useEffect(() => { dispatch(actionCreators.loadSellerByBirCode()) }, [])
  useEffect(() => { dispatch(actionCreators.loadBuyersByBirCode(buyersPayload)) }, [buyersPayload])
  useEffect(() => { dispatch(actionCreators.setBuyersLastVisitedTab(curActiveKey)) }, [curActiveKey])

  const csvData = buyersState?.data?.content.map((x => ({
    [t("Account.Columns.businessname")]: x.businessname,
    [t("Account.Columns.SellersTable.tresorcode")]: x.tresorcode,
    [t("Account.Columns.bircode")]: x.bircode,
    [t("Account.Columns.validitystartdate")]: x.accountClientCodes[0].validitystartdate ? moment(x.accountClientCodes[0].validitystartdate).format('L') : '',
    [t("Account.Columns.validityenddate")]: x.accountClientCodes[0].validityenddate ? moment(x.accountClientCodes[0].validityenddate).format('L') : '',
    [t("Account.Columns.clienttype")]: x.clienttype,
    [t("Account.Columns.clientcode")]: x.accountClientCodes[0].clientcode.trim()
  })))

  const tabBarExtraContent = csvData && sellerData ? <>
    <Button
      style={{ marginRight: "10px" }}
      type="primary">
      <CSVLink
        filename={`buyers-${myBIRCodeData!.bircode}.csv`}
        data={csvData}
        separator=";"
      >
        {t("Generic.Actions.ExportToCSV")}
      </CSVLink>
    </Button>
    <Button onClick={() => handleReinitFilters()}>{t("Generic.Actions.ReinitialiseFilters")}</Button>
  </> : <CenteredSpin />

  const locale = useConfigTranslate();
  useEffect(() => {
    if (!history.location.state)
      handleReinitFilters()
  }, [locale])

  return (
    <PageContainer>
      <Tabs destroyInactiveTabPane={true} defaultActiveKey={defaultActiveKey} onChange={onChange}>
        <TabPane tab={t("Account.Navigation.Seller")} key="1">
          <AccountsTableWrapper>
            <Space style={{ width: "100%" }} direction="vertical" size="large">
              <SellerTable />
              <BuyersTable tabBarExtraContent={tabBarExtraContent} setPayload={setBuyersPayload} payload={{ ...buyersPayload }} columns={[...buyersColumns]} setColumns={setBuyersColumns} key={tableKey} />
            </Space>
          </AccountsTableWrapper>
        </TabPane>
      </Tabs>
    </PageContainer>
  );
};
