import { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';

export type ID = string | number

export interface IPage<T> {
  content: T[]
  pageable: any
  number: number
  size: number
  totalElements: number
  totalPages: number
  sort: any
  numberOfElements: number
  first: boolean
  last: boolean
  empty: boolean
}

export interface Route {
  name: string,
  path: string,
  component?: ComponentType<RouteComponentProps<any>> | ComponentType<any>,
  isProtected?: boolean,
  isPrivate?: boolean,
  isBircodeRequired?: boolean,
  routes?: Route[],
};

export type DataWithRender = {
  label: string,
  value?: string,
  render?: JSX.Element
}
export interface Page {
  number: number
  size: number
  totalElements: number
  totalPages: number
}

export interface IRestData<T> {
  page?: Page
  _embedded: T[] | any
  _links?: any
}


export interface Payload {
  // Filter
  [x: string]: any

  // Pagination
  page?: number
  size?: number

  // Sort
  sort?: string
  name?: string
}

export interface PageProps {
  routes: Route[]
}

export enum SortDirection {
  ascend = "asc",
  descend = "desc"
}
