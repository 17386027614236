import { Action } from 'redux-actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  apiCallWrapper,
  defaultUser,
  DEFAULT_DELAY,
  delay,
  useDefaultUser
} from 'shared'
import * as actions from './actions'
import { actionCreators as alertActions } from 'shared/components/RPAlert/stores/actions'
import * as utils from './utils'
import { RpUser } from './types'
import { User, UserSettings } from 'oidc-client'
import * as roleActions from "screens/context/stores/actions"
import { getRoleDataFromUser } from "screens/context/stores/utils"

/**
 * Login Sagas (works like @ngrx/effects in Angular)
 */
function* Login() {
  try {
    const user: RpUser = yield apiCallWrapper<RpUser>(utils.authenticatedUser)
    yield call(loginUser, user)

  } catch (error) {
    yield put(alertActions.alertMsg(utils.createAlert(error)))
    yield put(actions.actionCreators.authLoginFailed())
  }
}

function* Logout() {
  try {
    yield call([localStorage, localStorage.clear])
    yield put(roleActions.actionCreators.setToEmptyMyBirCode())
  } catch (error) {
    console.log('[SAGA] Logout failed: ', error)
  }
}

export function* LoginRefreshToken() {
  try {
    const user: User = yield apiCallWrapper<User>(utils.userRenewToken)
    yield call(loginUser, user)
  } catch (error) {
    yield put(alertActions.alertMsg(utils.createAlert(error)))
    yield put(actions.actionCreators.authLoginFailed())
  }
}

function* LoginSuccess(action: Action<RpUser>) {
  yield put(roleActions.actionCreators?.setMyRole(getRoleDataFromUser(action.payload)))
  yield delay(DEFAULT_DELAY)
}

function* loginUser(user: User) {
  const userSettings = useDefaultUser ? new RpUser({
    ...defaultUser,
    access_token: user.access_token
  }) as UserSettings : user as UserSettings

  const rpUser = new RpUser(userSettings);

  yield put(actions.actionCreators.authLoginSuccess(rpUser))
}

export default function* () {
  yield takeLatest(actions.Type.AUTH_LOGIN, Login)
  yield takeLatest(actions.Type.AUTH_LOGOUT, Logout)
  yield takeLatest(actions.Type.AUTH_LOGIN_REFRESH_TOKEN, LoginRefreshToken)
  yield takeLatest(actions.Type.AUTH_LOGIN_SUCCESS, LoginSuccess)
}