import { FC } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../Styles";
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router'

interface ButtonBackProps {
    fallback: string
}

export const ButtonBack: FC<ButtonBackProps> = ({ fallback }) => {
    const history = useHistory();
    const { t } = useTranslation()

    return (
        <NavLink to={{ pathname: fallback, state: { prevPath: history.location.pathname } }}>
            <StyledButton icon={<ArrowLeftOutlined />} type="default">
                {t("Generic.Actions.Return")}
            </StyledButton>
        </NavLink>
    )
}