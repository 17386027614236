import { ColumnsType } from 'antd/lib/table';
import { Action, handleActions } from 'redux-actions';
import { IPage } from 'shared/utils/types';
import { Type } from './actions';
import { ErrorLogs, ErrorLogsRequestPayload, ErrorLogsState } from './types';

export const defaultPayload = {
  page: 0,
  size: 20,
  sort: "fileCreatedDate,desc"
}

const INITIAL_STATE: ErrorLogsState = {
  loading: false,
  errorLogsPayload: defaultPayload,
  errorLogsData: undefined,
  navigationSelectedKey: [''],
  showNavigation: false,
  errorLogsColumns: undefined
}

export default handleActions<ErrorLogsState, any>(
  {
    // LOAD ERROR LOGS
    [Type.LOAD_ERROR_LOGS]: (state: ErrorLogsState, action: Action<ErrorLogsRequestPayload>) => ({
      ...state,
      loading: true,
      errorLogsPayload: action.payload,
    }),
    [Type.LOAD_ERROR_LOGS_SUCCESS]: (state: ErrorLogsState, action: Action<IPage<ErrorLogs>>) => ({
      ...state,
      loading: false,
      errorLogsData: action.payload
    }),
    [Type.LOAD_ERROR_LOGS_ERROR]: (state: ErrorLogsState) => ({
      ...state,
      loading: false,
      errorLogsData: undefined
    }),

    // User PREFs
    [Type.TOGGLE_NAVIGATION_MENU]: (state: ErrorLogsState) => ({
      ...state,
      showNavigation: !state.showNavigation
    }),
    [Type.NAVIGATION_SELECTED_KEY]: (state: ErrorLogsState, action: Action<['']>) => ({
      ...state,
      navigationSelectedKey: action.payload
    }),
    [Type.SET_ERROR_LOGS_COLUMNS]: (state: ErrorLogsState, action: Action<ColumnsType<ErrorLogs>>) => ({
      ...state,
      errorLogsColumns: action.payload
    })
  },
  INITIAL_STATE
)