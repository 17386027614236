import { Input } from "antd"
import { SearchProps } from "antd/lib/input"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as orderActions, searchByRefLoadingSelector } from "screens/orders/stores"
import { SelectLabel, SelectWrapper } from "../Styles"
import { orderPopoverVisibleSelector, orderPopoverValueSelector, orderPopoverMsgSelector } from "../stores"
import { actionCreators as contextActions } from "../stores"

export const OrderSelect: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const orderByRefLoading = useSelector(searchByRefLoadingSelector)
    const visible = useSelector(orderPopoverVisibleSelector)
    const value = useSelector(orderPopoverValueSelector)
    const setValue = (value: string) => dispatch(contextActions.setOrderPopoverValue(value))
    const msg = useSelector(orderPopoverMsgSelector);
    const setMsg = (msg: string) => dispatch(contextActions.setOrderPopoverMsg(msg))

    useEffect(() => {
        if (!visible) {
            setValue("")
            setMsg("")
        }
    }, [visible])

    const inputSearchProps: SearchProps = {
        autoFocus: true,
        placeholder: t("Countries.OrderNumber"),
        value,
        style: { width: '400px' },
        loading: orderByRefLoading,
        onSearch: value => {
            const filters: { [key: string]: boolean } = {
                [t("Countries.YouMustEnterAValue")]: value?.length > 0,
            };

            Object.keys(filters).forEach(key => !filters[key] && setMsg(key))
            if (!Object.values(filters).includes(false))
                dispatch(orderActions.searchByRef(value))

        },
        onChange: (event) => {
            setMsg("");
            setValue(event.target.value.replace(/\D/g, ''))
        }
    }

    return <SelectWrapper>
        <SelectLabel>{t(`Countries.SearchForAnOrderNumber`)}</SelectLabel>
        <Input.Search {...inputSearchProps} />
        {msg && <span style={{ color: "red" }}>{msg}</span>}
    </SelectWrapper>
}