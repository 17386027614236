import { FC } from "react";
import { actionCreators, Order, orderMassActionStatusSelector, ordersSelectedRowsSelector, OrderStatus } from "../stores";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderTableMassActionModal } from "./OrderTableMassActionModal";
import { MassActionModal } from "shared/components/Modal/MassActionModal";

export const OrderTableMassAction: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const orderMassActionStatus = useSelector(orderMassActionStatusSelector);
    const ordersSelectedRows = useSelector(ordersSelectedRowsSelector);

    const handleOk = () => {
        const filteredRows = ordersSelectedRows.filter((elem: Order) => {
            if (orderMassActionStatus === OrderStatus.Solded) {
                return (elem.status === OrderStatus.ValidationRequired) && elem.needReemissionDMS
            } else {
                return (elem.status === OrderStatus.ValidationRequired) || (elem.status === OrderStatus.DMSTreatment)
            }
        });
        const updatingRows = filteredRows.map((elem: Order) => ({ ...elem, status: orderMassActionStatus }));

        dispatch(actionCreators.updateOrders({ orders: updatingRows }));
        dispatch(actionCreators.setSelectedOrders([]));
    };

    return <>
        {
            ordersSelectedRows && ordersSelectedRows.length > 0 && <>
                <MassActionModal
                    onOk={handleOk}
                    title={t("Generic.Actions.MassEdit")}
                >
                    <OrderTableMassActionModal />
                </MassActionModal>
            </>
        }
    </>
} 