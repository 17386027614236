import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Menu } from 'antd';
import {
  DislikeOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  RetweetOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { StyledMenu } from '../Styles';
import { actionCreators } from 'screens/login/stores/actions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RolePrivilege, myRolePrivilegeSelector } from 'screens/context/stores';

export const HeaderMenu: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const logout = () => dispatch(actionCreators.authLogout());
  const rolePrivilege = useSelector(myRolePrivilegeSelector);

  const menuItemsData = [{
    props: {
      key: "/home",
      icon: <HomeOutlined />,
      title: t("Generic.Menu.Home"),
    },
    content: <Link to="/home"> {t("Generic.Menu.Home")}</Link>,
    visible: true,
  },
  {
    props: {
      key: "/accounts/tracking",
      icon: <UsergroupAddOutlined />,
      title: t("Generic.Menu.Account"),
    },
    content: <Link to="/accounts/tracking"> {t("Generic.Menu.Account")}</Link>,
    visible: true,
  },
  {
    props: {
      key: "/orders/tracking",
      icon: <ShoppingCartOutlined />,
      title: t("Generic.Menu.Orders"),
    },
    content: <Link to="/orders/tracking"> {t("Generic.Menu.Orders")}</Link>,
    visible: rolePrivilege !== RolePrivilege.COUNTRY_SUBSCRIPTION
  },
  {
    props: {
      key: "/errors",
      icon: <DislikeOutlined />,
      title: t("Generic.Menu.ErrorLogs"),
    },
    content: <Link to="/error-logs/tracking"> {t("Generic.Menu.ErrorLogs")}</Link>,
    visible: rolePrivilege !== RolePrivilege.COUNTRY_SUBSCRIPTION
  },
  {
    props: {
      key: "/stockExchange",
      icon: <RetweetOutlined />,
      title: t("Generic.Menu.StockExchange"),
    },
    content: <Link to="/stock-exchange/tracking"> {t("Generic.Menu.StockExchange")}</Link>,
    visible: rolePrivilege !== RolePrivilege.COUNTRY_SUBSCRIPTION
  },
  {
    props: {
      key: "/logout",
      icon: <LogoutOutlined />,
      title: t("Generic.Menu.LogOut"),
      onClick: logout,
    },
    content: t("Generic.Menu.LogOut"),
    visible: true
  }]

  return (
    <StyledMenu key="menu" mode="horizontal" selectedKeys={['']}>
      <Menu.SubMenu
        key="1"
        icon={<MenuUnfoldOutlined />}
      >
        {menuItemsData.map((menuItem: any) => menuItem.visible && <Menu.Item {...menuItem.props}>{menuItem.content}</Menu.Item>)}
      </Menu.SubMenu>
    </StyledMenu>
  );
};
