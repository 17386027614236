import { combineReducers } from '@reduxjs/toolkit'
// RP4 Reducers
import apiReducers from './api/reducers'
import rpAlertReducer from 'shared/components/RPAlert/stores/reducer'
import loginReducer from 'screens/login/stores/reducer'
import ordersReducer from 'screens/orders/stores/reducer'
import errorLogsReducer from 'screens/errorLogs/stores/reducer'
import accountsReducer from 'screens/accounts/stores/reducer'
import rolesReducer from 'screens/context/stores/reducer'
import stockExchangeReducer from "screens/stockExchange/stores/reducer"

/**
 * Root Reducers (All RP4 Reducers)
 */
export const rootReducer = combineReducers({
  api: apiReducers,
  alert: rpAlertReducer,
  login: loginReducer,
  orders: ordersReducer,
  errorLogs: errorLogsReducer,
  accounts: accountsReducer,
  roles: rolesReducer,
  stockExchange: stockExchangeReducer
})

export default rootReducer