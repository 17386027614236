import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { useSellersColumns } from '../hooks/useSellersColumns';
import {
  sellerLoadingSelector,
  sellerDataSelector
} from '../stores/selectors';
import { GarageData } from "../stores"

/**
 * AccountDetails Component
 */
export const SellerTable: FC = () => {
  const sellerLoading = useSelector(sellerLoadingSelector);
  const sellerData = useSelector(sellerDataSelector);

  const pagination = false;
  const data: GarageData[] = sellerData ? [sellerData.garageData] : []

  const columns = useSellersColumns();

  return (
    <Table
      size="middle"
      loading={sellerLoading}
      dataSource={data}
      pagination={pagination}
      columns={columns}
      rowClassName={"clickable"}
      rowKey="id"
    />
  );
};
