import { createAction } from 'redux-actions';
import { ID, IPage, IRestData } from 'shared/utils/types';
import { Order, OrderEvents, OrderItem, UpdateOrderItems, OrderList, OrderStatus } from './types';
import { Payload } from 'shared/utils/types';

export enum Type {
  LOAD_ORDERS = 'LOAD_ORDERS',
  LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS',
  LOAD_ORDERS_ERROR = 'LOAD_ORDERS_ERROR',
  PUT_ORDER = 'PUT_ORDER',
  PUT_ORDER_SUCCESS = 'PUT_ORDER_SUCCESS',
  PUT_ORDERS = 'PUT_ORDERS',
  PUT_ORDERS_SUCCESS = 'PUT_ORDERS_SUCCESS',
  PUT_ORDERS_ERROR = 'PUT_ORDERS_ERROR',
  SET_SELECTED_ORDERS = 'SET_SELECTED_ORDERS',
  ORDER_MASSACTION_STATUS = 'ORDER_MASSACTION_STATUS',
  TOGGLE_NAVIGATION_MENU = 'TOGGLE_NAVIGATION_MENU',
  NAVIGATION_SELECTED_KEY = 'NAVIGATION_SELECTED_KEY',
  GET_ORDER_ITEMS = 'GET_ORDER_ITEMS',
  GET_ORDER_ITEMS_SUCCESS = 'GET_ORDER_ITEMS_SUCCESS',
  GET_ORDER_ITEMS_ERROR = 'GET_ORDER_ITEMS_ERROR',
  GET_ORDER_ITEMS_WITH_STOCKS = 'GET_ORDER_ITEMS_WITH_STOCKS',
  GET_ORDER_ITEMS_WITH_STOCKS_SUCCESS = 'GET_ORDER_ITEMS_WITH_STOCKS_SUCCESS',
  GET_ORDER_ITEMS_WITH_STOCKS_ERROR = 'GET_ORDER_ITEMS_WITH_STOCKS_ERROR',
  GET_ORDER_EVENTS = 'GET_ORDER_EVENTS',
  GET_ORDER_EVENTS_SUCCESS = 'GET_ORDER_EVENTS_SUCCESS',
  GET_ORDER_EVENTS_ERROR = 'GET_ORDER_EVENTS_ERROR',
  RETRANSMISSION_TO_DMS = 'RETRANSMISSION_TO_DMS',
  RETRANSMISSION_TO_DMS_SUCCESS = 'RETRANSMISSION_TO_DMS_SUCCESS',
  RETRANSMISSION_TO_DMS_ERROR = 'RETRANSMISSION_TO_DMS_ERROR',
  LAST_VISITED_TAB_ORDERS = 'LAST_VISITED_TAB_ORDERS',
  SET_COLUMNS = 'SET_COLUMNS',
  UPDATE_ORDER_ITEMS_WANTED_QUANTITY = 'UPDATE_ORDER_ITEMS_WANTED_QUANTITY',
  UPDATE_ORDER_ITEMS_WANTED_QUANTITY_SUCCESS = 'UPDATE_ORDER_ITEMS_WANTED_QUANTITY_SUCCESS',
  UPDATE_ORDER_ITEMS_WANTED_QUANTITY_ERROR = 'UPDATE_ORDER_ITEMS_WANTED_QUANTITY_ERROR',
  UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST = 'UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST',
  UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_SUCCESS = 'UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_SUCCESS',
  UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_ERROR = 'UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_ERROR',
  GET_BY_REF = "GET_BY_REF",
  GET_BY_REF_ERROR = "GET_BY_REF_ERROR",
  GET_BY_REF_SUCCESS = "GET_BY_REF_SUCCESS",
  SEARCH_BY_REF = "SEARCH_BY_REF",
  SEARCH_BY_REF_ERROR = "SEARCH_BY_REF_ERROR",
  SEARCH_BY_REF_SUCCESS = "SEARCH_BY_REF_SUCCESS"
}

export const actionCreators = {
  loadOrders: createAction<Payload, Payload>(Type.LOAD_ORDERS, (payload: Payload) => payload),
  loadOrdersSuccess: createAction<IPage<Order>, IPage<Order>>(Type.LOAD_ORDERS_SUCCESS, (ordersPage: IPage<Order>) => ordersPage),
  loadOrdersError: createAction(Type.LOAD_ORDERS_ERROR),
  setColumns: createAction<any, any>(Type.SET_COLUMNS, (payload: any) => payload),
  updateOrder: createAction<Order, Order>(Type.PUT_ORDER, (payload: Order) => payload),
  updateOrderSuccess: createAction<Order, Order>(Type.PUT_ORDER_SUCCESS, (payload: Order) => payload),
  updateOrders: createAction<OrderList, OrderList>(Type.PUT_ORDERS, (payload: OrderList) => payload),
  updateOrdersSuccess: createAction<OrderList, OrderList>(Type.PUT_ORDERS_SUCCESS, (payload: OrderList) => payload),
  updateOrdersError: createAction(Type.PUT_ORDERS_ERROR),
  toggleNavigationOpen: createAction(Type.TOGGLE_NAVIGATION_MENU),
  navigationSelectedKey: createAction<Array<string>, Array<string>>(Type.NAVIGATION_SELECTED_KEY, (payload: Array<string>) => payload),
  setSelectedOrders: createAction<Array<Order>, Array<Order>>(Type.SET_SELECTED_ORDERS, (payload: Array<Order>) => payload),
  ordersMassactionStatus: createAction<OrderStatus, OrderStatus>(Type.ORDER_MASSACTION_STATUS, (payload: OrderStatus) => payload),
  getOrderItems: createAction<ID, ID>(Type.GET_ORDER_ITEMS, (payload: ID) => payload),
  getOrderItemsSuccess: createAction<IRestData<OrderItem>, IRestData<OrderItem>>(Type.GET_ORDER_ITEMS_SUCCESS, (payload: IRestData<OrderItem>) => payload),
  getOrderItemsError: createAction(Type.GET_ORDER_ITEMS_ERROR),
  getOrderItemsWithStocks: createAction<ID, ID>(Type.GET_ORDER_ITEMS_WITH_STOCKS, (payload: ID) => payload),
  getOrderItemsWithStocksSuccess: createAction<IRestData<OrderItem>, IRestData<OrderItem>>(Type.GET_ORDER_ITEMS_WITH_STOCKS_SUCCESS, (payload: IRestData<OrderItem>) => payload),
  getOrderItemsWithStocksError: createAction(Type.GET_ORDER_ITEMS_WITH_STOCKS_ERROR),
  getOrderEvents: createAction<ID, ID>(Type.GET_ORDER_EVENTS, (payload: ID) => payload),
  getOrderEventsSuccess: createAction<Array<OrderEvents>, Array<OrderEvents>>(Type.GET_ORDER_EVENTS_SUCCESS, (payload: Array<OrderEvents>) => payload),
  getOrderEventsError: createAction(Type.GET_ORDER_EVENTS_ERROR),
  searchByRef: createAction<string, string>(Type.SEARCH_BY_REF, (payload: string) => payload),
  searchByRefSuccess: createAction<Order, Order>(Type.SEARCH_BY_REF_SUCCESS, (payload: Order) => payload),
  searchByRefError: createAction(Type.SEARCH_BY_REF_ERROR),
  getByRef: createAction<string, string>(Type.GET_BY_REF, (payload: string) => payload),
  getByRefSuccess: createAction<Order, Order>(Type.GET_BY_REF_SUCCESS, (payload: Order) => payload),
  getByRefError: createAction(Type.GET_BY_REF_ERROR),
  retransmissionToDMS: createAction<ID, ID>(Type.RETRANSMISSION_TO_DMS, (payload: ID) => payload),
  retransmissionToDMSSuccess: createAction<Order, Order>(Type.RETRANSMISSION_TO_DMS_SUCCESS, (payload: Order) => payload),
  retransmissionToDMSError: createAction(Type.RETRANSMISSION_TO_DMS_ERROR),
  updateOrderItemsWantedQuantity: createAction<UpdateOrderItems, UpdateOrderItems>(Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY, ((payload: UpdateOrderItems) => payload)),
  updateOrderItemsWantedQuantitySuccess: createAction<OrderItem, OrderItem>(Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_SUCCESS, ((payload: OrderItem) => payload)),
  updateOrderItemsWantedQuantityError: createAction(Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_ERROR),
  updateOrderItemsWantedQuantityList: createAction<Array<UpdateOrderItems>, Array<UpdateOrderItems>>(Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST, ((payload: Array<UpdateOrderItems>) => payload)),
  updateOrderItemsWantedQuantityListSuccess: createAction(Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_SUCCESS),
  updateOrderItemsWantedQuantityListError: createAction(Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST_ERROR),
  setLastVisitedTabOrders: createAction<string, string>(Type.LAST_VISITED_TAB_ORDERS, (payload: string) => payload)
}