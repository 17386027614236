import { Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageContainer } from 'shared/GlobalStyles';
import { useErrorLogsColumns } from "../hooks/useErrorLogsColumns";
import { ColumnsType } from "antd/lib/table";
import {
    errorLogsPayloadSelector,
    errorLogsColumnsSelector,
    ErrorLogsRequestPayload,
    ErrorLogs,
    defaultPayload
} from "../stores";
import { ErrorLogsTable } from "../components/ErrorLogsTable";
import { actionCreators } from "../stores/actions"
import { useConfigTranslate } from "shared/hooks/useConfigTranslate";
import { ErrorLogsForm } from "../components/ErrorLogsForm";

export const ErrorLogsTracking: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const defaultColumns = useErrorLogsColumns();
    const errorLogsPayload = useSelector(errorLogsPayloadSelector)
    const errorLogsColumns = useSelector(errorLogsColumnsSelector)
    const [showRes, setShowRes] = useState(false);

    const columns = errorLogsColumns ? errorLogsColumns : defaultColumns;

    const setPayload = (payload: ErrorLogsRequestPayload) => dispatch(actionCreators.loadLogs(payload))
    const setColumns = (columns: ColumnsType<ErrorLogs>) => dispatch(actionCreators.setErrorLogsColumns(columns))
    const [key, setKey] = useState(0);

    useEffect(() => {
        setPayload(errorLogsPayload)
    }, [])

    const locale = useConfigTranslate();
    useEffect(() => {
        handleReinitFilters()
    }, [locale])

    const handleReinitFilters = () => {
        reinitTab()
        setKey(key + 1)
    }

    const reinitTab = () => {
        setColumns(defaultColumns)
        setPayload(defaultPayload)
    }

    return <PageContainer>
        <Tabs>
            <Tabs.TabPane
                tab={t("ErrorLogs.Navigation.Tabs")}
                key={key}
            >
                <ErrorLogsForm onValidation={() => setShowRes(true)} payload={errorLogsPayload} setPayload={setPayload}></ErrorLogsForm>
                {showRes && <ErrorLogsTable
                    columns={columns}
                    payload={errorLogsPayload}
                    setColumns={setColumns}
                    setPayload={setPayload}
                />}
            </Tabs.TabPane>
        </Tabs>
    </PageContainer>
}