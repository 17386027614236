import { BuyerDetails } from '../pages/BuyerDetails';
import { SellerDetails } from '../pages/SellerDetails';
import { AccountsTracking } from '../pages/AccountsTracking';
import { Route } from 'shared/utils/types';

/**
 *  Routes of Accounts Module
 */
export const AccountsRoutes: Route[] = [
  {
    name: "tracking",
    path: "/tracking",
    component: AccountsTracking,
    isProtected: true,
    isBircodeRequired: true
  },
  {
    name: 'seller',
    path: '/seller',
    component: SellerDetails,
    isProtected: true,
    isBircodeRequired: true
  },
  {
    name: "buyer",
    path: "/buyer/:id",
    component: BuyerDetails,
    isProtected: true,
    isBircodeRequired: true
  }
];
