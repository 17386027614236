import { Button, Tabs } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCountry } from 'screens/accounts/hooks/useCountry';
import { CenteredSpin } from 'shared/components/Styles';
import { PageContainer } from 'shared/GlobalStyles';
import { useConfigTranslate } from 'shared/hooks/useConfigTranslate';
import { Payload } from 'shared/utils/types';
import { OrdersTable } from '../components/OrdersTable';
import { useOrderTable } from '../hooks/useOrderTable';
import {
  actionCreators,
  lastVisitedTabOrdersSelector,
  ordersColumnsSelector,
  OrdersPayloadSelector
} from '../stores';

const { TabPane } = Tabs;

/**
 * Orders Tracking page - Used to display orders tracking
 */
const OrdersTracking: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory();

  const lastVisitedTabOrders = useSelector(lastVisitedTabOrdersSelector)
  const ordersColumns = useSelector(ordersColumnsSelector)
  const PageablePayload = useSelector(OrdersPayloadSelector)

  const country = useCountry();

  const {
    ordersWaitingForActionTable,
    ordersInProgressTable,
    ordersNeedReissueDMS,
    ordersAll,
    ordersFinishedTable
  } = useOrderTable();

  const orderTrackingData: any = {
    "1": {
      key: "1",
      tab: t("Orders.Navigation.OrdersWaitingForAction"),
      ...ordersWaitingForActionTable
    },
    "2": {
      key: "2",
      tab: t("Orders.Navigation.OrdersInProgress"),
      ...ordersInProgressTable
    },
    "3": {
      key: "3",
      tab: t("Orders.Navigation.OrdersNeedReissueDMS"),
      ...ordersNeedReissueDMS
    },
    "4": {
      key: "4",
      tab: t("Orders.Navigation.OrdersAll"),
      ...ordersAll
    },
    "5": {
      key: "5",
      tab: t("Orders.Navigation.OrdersFinished"),
      ...ordersFinishedTable
    }
  }

  const [columns, setColumns] = useState(lastVisitedTabOrders && ordersColumns ? ordersColumns : orderTrackingData["1"].columns);
  const [payload, setPayload] = useState<Payload>(lastVisitedTabOrders ? PageablePayload : orderTrackingData["1"].payload);
  const [curActiveKey, setCurActiveKey] = useState<string>(lastVisitedTabOrders ? lastVisitedTabOrders : "1");
  const [tableKey, setTableKey] = useState<number>(0);

  const defaultActiveKey = lastVisitedTabOrders ? lastVisitedTabOrders : "1"

  const reinitTab = (activeKey: string) => {
    setColumns(orderTrackingData[activeKey].columns);
    setPayload(orderTrackingData[activeKey].payload);
    dispatch(actionCreators.setSelectedOrders([]))
  }

  const handleReinitFilters = (activeKey: string) => {
    reinitTab(activeKey);
    setTableKey(tableKey + 1)
  }

  const onChange = (activeKey: string) => {
    reinitTab(activeKey)
    setCurActiveKey(activeKey)
  }

  useEffect(() => { dispatch(actionCreators.setColumns(columns)) }, [columns])
  useEffect(() => { dispatch(actionCreators.loadOrders(payload)) }, [payload])
  useEffect(() => { dispatch(actionCreators.setLastVisitedTabOrders(curActiveKey)) }, [curActiveKey])

  const refreshOrders = useCallback(() => {
    dispatch(actionCreators.loadOrders(payload));
    dispatch(actionCreators.setColumns(columns));
    dispatch(actionCreators.setLastVisitedTabOrders(curActiveKey));
  }, [columns, curActiveKey, payload]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshOrders();
    }, 60000);
    return () => clearInterval(interval);
  }, [refreshOrders]);

  const tabBarExtraContent = (
    <>
      <Button onClick={() => handleReinitFilters(curActiveKey)}>{t("Generic.Actions.ReinitialiseFilters")}</Button>
      <Button onClick={() => refreshOrders()}>{t("Generic.Actions.RefreshOrders")}</Button>
    </>
  );

  const locale = useConfigTranslate();
  useEffect(() => {
    if (!history.location.state)
      handleReinitFilters(curActiveKey)
  }, [locale])

  return (
    <PageContainer>
      <Tabs
        tabBarExtraContent={tabBarExtraContent} destroyInactiveTabPane={true} defaultActiveKey={defaultActiveKey} onChange={onChange}
      >
        {Object.keys(orderTrackingData).map((key: string) =>
          <TabPane tab={orderTrackingData[key].tab} key={orderTrackingData[key].key}>

            {country && <OrdersTable
              setPayload={setPayload}
              payload={{ ...payload }}
              columns={[...columns]}
              setColumns={setColumns}
              key={tableKey}
            />}

            {
              !country && <CenteredSpin />
            }

          </TabPane>
        )}
      </Tabs>
    </PageContainer>
  );
};

export default OrdersTracking;