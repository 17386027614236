import { FC, createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { actionCreators, navSelectedKey, toggleNavigation } from './stores';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { CustomItem } from './Styles';
import { ToggleButton, ToggleWrapper } from './Styles';
import { useTranslation } from 'react-i18next';

/**
 * Navigation of module Orders
 */
export const OrdersNavigation: FC = () => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const showMenu = useSelector(toggleNavigation);
  const selectedKey = useSelector(navSelectedKey);
  const dispatch = useDispatch();

  const toggleCollapsed = () => dispatch(actionCreators.toggleNavigationOpen())
  const handleClick = (e: any) => dispatch(actionCreators.navigationSelectedKey([e.key]));

  return (
    <Menu
      mode="inline"
      inlineCollapsed={showMenu}
      selectedKeys={selectedKey}
      onClick={handleClick}
    >
      <ToggleWrapper $collapsed={showMenu}>
        <ToggleButton
          onClick={toggleCollapsed}
          type="primary"
          $collapsed={showMenu}
        >
          {createElement(
            showMenu ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </ToggleButton>
      </ToggleWrapper>

      <CustomItem
        key=""
        icon={<OrderedListOutlined />}
      >
        <NavLink to={`${url}/tracking`}>{t("Orders.Navigation.OrdersFollowing")}</NavLink>
      </CustomItem>
    </Menu>
  );
};
