import { Button, Menu } from 'antd';
import styled, { keyframes } from 'styled-components';
import { BasicContainer } from '../../Styles';

/**
 * All ErrorLogs Styled (need review)
 */
export const DisplayContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: 33em;
  width: 100%;
`;

export const ErrorsTableWrapper = styled.div`
  min-height: 340px;
`;

interface ToggleProps {
  $collapsed: boolean
}

const slideRightAnimation = keyframes`
  from {
    left: 0;
  }
  to {
    left: 152px;
  }
 `;

export const ToggleWrapper = styled.div`
  width: ${(props: ToggleProps) => !props.$collapsed ? '198.36px' : null};
  display: flex;
`;

export const ToggleButton = styled(Button)`
  width: ${(props: ToggleProps) => props.$collapsed ? '100%' : null};
  animation: ${(props: ToggleProps) => !props.$collapsed ? slideRightAnimation : null} .3s forwards 1;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomItem = styled(Menu.Item)`
  color: #000;
  // font-weight: 500;
`;

export const ErrorsLogsTableWrapper = styled.div`
  min-height: 340px;
`;

export const ErrorLogsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;
`;
