import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { FormInstance } from "antd";
import { Field, Value, ActionButton, InfosRow, DetailsCard } from '../Styles';
import moment from "moment"
import { Col, Spin } from 'antd';
import { actionCreators, Buyer, buyersSelector } from "../stores";
import { useDispatch, useSelector } from "react-redux";

interface BuyerInfoProps {
    buyer: Buyer
    form: FormInstance<any>
}

export const BuyerInfo: FC<BuyerInfoProps> = ({ buyer, form }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading } = useSelector(buyersSelector)

    const handleSubscribe = () => {
        form.validateFields()
            .then(() => dispatch(actionCreators.subscribeBuyerMass({ buyers: [buyer.accountbuyers[0].id] })))
    }

    const handleUnsubscribe = () => {
        form.validateFields()
            .then(() => dispatch(actionCreators.unsubscribeBuyerMass({ buyers: [buyer.accountbuyers[0].id] })))
    }

    return (
        <InfosRow gutter={[8, 8]}>
            <Col>
                <DetailsCard>
                    <div className="fields-wrapper">
                        <Field>
                            <span>{t("Account.Details.CommercialName")} :</span>
                            <Value>{buyer.businessname}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.TresorCode")} :</span>
                            <Value>{buyer.tresorcode}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.Bircode")} :</span>
                            <Value>{buyer.bircode}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.BuildingNumber")} :</span>
                            <Value>{buyer.establishmentnum}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.GarageType")} :</span>
                            <Value>{buyer.clienttype}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.GarageLanguage")} :</span>
                            <Value>{buyer.state}</Value>
                        </Field>
                    </div>
                </DetailsCard>
            </Col>
            <Col>
                <DetailsCard>
                    <Field>
                        <span>{t("Account.Details.Address")} :</span>
                        <Value>{buyer.address1}</Value>
                    </Field>
                    {buyer.address2 && <Field>
                        <span>-</span>
                        <Value>{buyer.address2}</Value>
                    </Field>}
                    {buyer.address3 && <Field>
                        <span>-</span>
                        <Value>{buyer.address3}</Value>
                    </Field>}
                    <Field>
                        <span>{t("Account.Details.PostalCode")} :</span>
                        <Value>{buyer.zipcode}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.City")} :</span>
                        <Value>{buyer.city}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.Phone")} :</span>
                        <Value>{buyer.phonenbr}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.Fax")} :</span>
                        <Value>{buyer.fax}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.ProfessionnalEmail")} :</span>
                        <Value>{buyer.profemailadd}</Value>
                    </Field>
                </DetailsCard>
            </Col>

            <Col>

                <DetailsCard>
                    <Spin spinning={loading} >
                        <Field>
                            <span>{t("Account.Details.ValidityStartDate")} :</span>
                            {buyer.accountbuyers[0].validationStartDate && <Value>{moment(buyer.accountbuyers[0].validationStartDate).format('L LT')}</Value>}
                        </Field>
                        <Field>
                            <span>{t("Account.Details.ValidityEndDate")} :</span>
                            {buyer.accountbuyers[0].validationEndDate && <Value>{moment(buyer.accountbuyers[0].validationEndDate).format('L LT')}</Value>}
                        </Field>
                        <Field style={{ paddingTop: "20px" }}>
                            <ActionButton disabled={!buyer.accountbuyers[0].subscribeAllowed} type="primary" onClick={handleSubscribe} > {t("Generic.Actions.Subscribe")} </ActionButton>
                            <ActionButton disabled={!buyer.accountbuyers[0].unsubscribeAllowed} type="default" onClick={handleUnsubscribe} > {t("Generic.Actions.Unsubscribe")} </ActionButton>
                        </Field>
                    </Spin>
                </DetailsCard>

            </Col>
        </InfosRow>
    );
}