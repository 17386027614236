import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useUrgentCountriesList } from "screens/accounts/hooks/useUrgentCountriesList";
import { TroncatedText } from "shared/components/TroncatedText/TroncatedText";
import { DataWithRender } from "shared/utils/types";
import { OrderStatusTag } from "../components/OrderStatusTag";
import { orderByRefDataSelector } from "../stores";

export function useOrderDetailsInfo() {
    const { t } = useTranslation();
    const order = useSelector(orderByRefDataSelector);
    const urgentCountriesList = useUrgentCountriesList();

    const displayDate = () =>
        order?.lastUpdateDate
            ? moment(order?.lastUpdateDate).format('L LT')
            : moment(order?.creationDate).format('L LT');

    const orderDetailsMainInfos: DataWithRender[] = [
        {
            label: t("Orders.Details.OrderNum"),
            value: order?.orderRef
        },
        {
            label: t("Orders.Columns.takenIntoAccount"),
            value: (order?.arccreateddate || order?.serveddate) ? t("Generic.Actions.Yes") : t("Generic.Actions.No")
        },
        {
            label: t("Orders.Columns.dmsnbr"),
            value: order?.dmsnbr
        },
        {
            label: t("Orders.Columns.urgentFlag"),
            value: order && urgentCountriesList.includes(order.countryCode) && order.urgentFlag ? t("Generic.Actions.Yes") : t("Generic.Actions.No")
        },
        {
            label: t("Orders.Details.DateHours"),
            value: displayDate()
        },
        {
            label: t("Orders.Details.OrderedBy"),
            value: order?.buyerName
        },
        {
            label: t("Orders.Details.TreatedBy"),
            value: order?.sellerName
        }
    ]
    const orderDetailsDeliveryInfos: DataWithRender[] = [
        {
            label: t("Orders.Details.Status"),
            value: order && t(`Orders.StatusName.${order.status}`),
            render: order && <OrderStatusTag status={order.status} />
        },
        {
            label: t("Orders.Details.Distributer"),
            value: order?.sellerName
        },
        {
            label: t("Orders.Details.Delivery"),
            value: t(`Generic.DeliveryMethod.${order?.deliveryMethod}`)
        },
        {
            label: t("Orders.Details.OrderMark"),
            value: order?.orderComment
        },
        {
            label: t("Orders.Columns.message"),
            value: order?.messageToSeller,
            render: order && <TroncatedText value={order.messageToSeller} />
        },
        {
            label: t("Orders.Columns.HeaderErrorLabel"),
            value: order?.headererrorlabel,
            render: order && <TroncatedText value={order.headererrorlabel} />
        },
    ]

    return {
        orderDetailsMainInfos,
        orderDetailsDeliveryInfos
    }
}