import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  isRolePrivilegeGranted,
  myRoleListSelector,
  RolePrivilege,
  myBIRCodeDataSelector,
  garagePopoverVisibleSelector,
  actionCreators
} from '../stores'

import { myCountriesLoadingSelector } from 'screens/accounts/stores'

import { Popover, PopoverProps } from 'antd'
import { HeaderToggleButton, ContextSpinner } from '../Styles'
import { GarageSelect } from './GarageSelect'
import { Loader } from 'shared/components/Loader/Loader'

export const GaragePopover: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loading = useSelector(myCountriesLoadingSelector)
  const selectedBircode = useSelector(myBIRCodeDataSelector)
  const roles = useSelector(myRoleListSelector)
  const visible = useSelector(garagePopoverVisibleSelector)
  const setVisible = (visible: boolean) => dispatch(actionCreators.setGaragePopoverVisible(visible))

  const popoverProps: PopoverProps = {
    destroyTooltipOnHide: true,
    visible,
    trigger: "click",
    placement: "top",
    onVisibleChange: () => setVisible(!visible),
    content: <Loader Spin={ContextSpinner} loading={loading}> <GarageSelect /> </Loader>
  }

  if (roles === undefined || !isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION)) return <></>

  return <Popover {...popoverProps}>
    <HeaderToggleButton>
      {(selectedBircode && `${selectedBircode.businessName} - ${selectedBircode.bircode}`) ||
        t("Countries.garage")}
    </HeaderToggleButton>
  </Popover>
};