import { api, API_ERROR_LOGS_SERVICE, getArgs, unwrapAxiosResult } from 'shared'
import { IPage } from 'shared/utils/types'
import { ErrorLogsRequestPayload, ErrorLogs } from './types'
import { getBirCode } from 'shared'

const DMS_LOGS_URI = `${API_ERROR_LOGS_SERVICE}/dms-files-integrations-status/search/by-seller-bir-code`

export const getErrorLogs = (payload: ErrorLogsRequestPayload) =>
  unwrapAxiosResult<IPage<ErrorLogs>>(api.get<IPage<ErrorLogs>>(
    `${DMS_LOGS_URI}?r1GarageId=${getBirCode()}&${getArgs(payload)}`
  ))