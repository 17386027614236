import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';

import { loadingSelector } from './stores/selectors';
import { actionCreators } from './stores/actions';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import {
  LoginWrapper,
  ContactWrapper,
  LoginButton,
  IconAdvisorWrapper,
  IconAdvisor,
  Contact,
  ByPhoneNumber,
  PhoneNumber,
  TimeSlots,
  WelcomeWrapper,
  WelcomeTitle,
  WelcomeMessage,
  LoginLinkButton
} from './Styles';
import { CallRate } from 'shared/components/Styles';

import iconAdvisor from 'assets/img/icon-advisor.svg';
import { myRoleListSelector } from 'screens/context/stores';

import { OLD_BACKOFFICE_URL, RPARTS_URL } from 'shared/utils/constants'
import { Divider } from 'antd';

export const Login: FC = () => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const role = useSelector(myRoleListSelector);
  const loading = useSelector(loadingSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleConnect = () => {
    dispatch(actionCreators.authLogin());
  };

  const handleRedirectToOldBackoffice = () => window.location.href = OLD_BACKOFFICE_URL
  const handleRedirectToRparts = () => window.location.href = RPARTS_URL

  return (
    !isAuthenticated || role === undefined ?
      <LoginWrapper >
        <WelcomeWrapper>
          <WelcomeTitle>{t("loginPage.welcomeTilte")}</WelcomeTitle>
          <WelcomeMessage>{t("loginPage.welcomeMessage")}</WelcomeMessage>
          <LoginButton
            onClick={() => handleConnect()}
            size="large"
            loading={loading}
            icon={<RightOutlined />}
            title={t("loginPage.LogToBO")}
          >
            {t("Generic.Actions.LogIn")}
          </LoginButton>
          <Divider style={{ minWidth: '95%', width: '95%', borderTop: '1px solid rgba(255,255,255,0.5)' }}></Divider>
          <WelcomeMessage style={{ marginTop: '20px' }}>
            {t("loginPage.oldBackofficeMessage")}
          </WelcomeMessage>
          <LoginLinkButton
            onClick={() => handleRedirectToOldBackoffice()}
            loading={loading}
            icon={<RightOutlined />}
            title={t("loginPage.GoToOldBackoffice")}
          >
            {t("Generic.Actions.LinkOldBackoffice")}

          </LoginLinkButton>
          <WelcomeMessage style={{ marginTop: '20px' }}>
            {t("loginPage.rpartsMessage")}
          </WelcomeMessage>
          <LoginLinkButton
            onClick={() => handleRedirectToRparts()}
            loading={loading}
            icon={<RightOutlined />}
            title={t("loginPage.GoToRparts")}
          >
            {t("Generic.Actions.LinkRparts")}
          </LoginLinkButton>
        </WelcomeWrapper>
        <ContactWrapper>
          <IconAdvisorWrapper>
            <IconAdvisor src={iconAdvisor} />
          </IconAdvisorWrapper>
          <Contact>{t("loginPage.contact")}</Contact>
          <ByPhoneNumber>{t("loginPage.byPhoneNumber")}</ByPhoneNumber>
          <PhoneNumber>{t("loginPage.phoneNumber")}</PhoneNumber>
          <CallRate>{t("loginPage.callRate")}</CallRate>
          <TimeSlots>{t("loginPage.timeSlots")}</TimeSlots>
        </ContactWrapper>
      </LoginWrapper> :
      <Redirect from="/login" to={{ pathname: "/home" }}></Redirect>
  );
};
