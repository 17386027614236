import { FC } from "react";
import { actionCreators, BuyersAction, buyersMassActionTypeSelector, buyersSelectedRowsSelector } from "../stores";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BuyersTableMassActionModal } from "./BuyersTableMassActionModal";
import { MassActionModal } from "shared/components/Modal/MassActionModal";

export const OrderTableMassAction: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const buyersMassActionType = useSelector(buyersMassActionTypeSelector);
    const buyersSelectedRows = useSelector(buyersSelectedRowsSelector);

    const handleOk = () => {
        if (buyersMassActionType === BuyersAction.SUBSCRIBE)
            dispatch(actionCreators.subscribeBuyerMass({ buyers: buyersSelectedRows.map(x => x.accountbuyers[0].id) }));
        else if (buyersMassActionType === BuyersAction.UNSUBSCRIBE)
            dispatch(actionCreators.unsubscribeBuyerMass({ buyers: buyersSelectedRows.map(x => x.accountbuyers[0].id) }));

        dispatch(actionCreators.setBuyersSelectedRows([]));
    };

    return <>
        {
            buyersSelectedRows && buyersSelectedRows.length > 0 && <>
                <MassActionModal onOk={handleOk} title={t("Generic.Actions.MassEdit")} >
                    <BuyersTableMassActionModal />
                </MassActionModal>
            </>
        }
    </>
} 