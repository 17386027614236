import * as Effects from 'redux-saga/effects'
import loginSaga from 'screens/login/stores/sagas'
import ordersSaga from 'screens/orders/stores/sagas'
import errorLogsSaga from 'screens/errorLogs/stores/sagas'
import accountsSaga from 'screens/accounts/stores/sagas'
import stockExchangeSaga from "screens/stockExchange/stores/sagas"

const { all, call } = Effects

export default function* rootSaga() {
    yield all([
        call(loginSaga),
        call(ordersSaga),
        call(accountsSaga),
        call(errorLogsSaga),
        call(stockExchangeSaga)
    ])
}