import { createAction } from 'redux-actions';
import { ID, IPage } from 'shared/utils/types';
import { StockExchange, StockExchangeLnk } from './types';
import { Payload } from 'shared/utils/types';
import { ColumnsType } from 'antd/lib/table';

export enum Type {
    LOAD_STOCK_EXCHANGE = "LOAD_STOCK_EXCHANGE",
    LOAD_STOCK_EXCHANGE_SUCCESS = "LOAD_STOCK_EXCHANGE_SUCCESS",
    LOAD_STOCK_EXCHANGE_ERROR = "LOAD_STOCK_EXCHANGE_ERROR",
    SET_STOCK_EXCHANGE_COLUMNS = "SET_STOCK_EXCHANGE_COLUMNS",

    LOAD_STOCK_EXCHANGE_LNK = "LOAD_STOCK_EXCHANGE_LNK",
    LOAD_STOCK_EXCHANGE_LNK_SUCCESS = "LOAD_STOCK_EXCHANGE_LNK_SUCCESS",
    LOAD_STOCK_EXCHANGE_LNK_ERROR = "LOAD_STOCK_EXCHANGE_LNK_ERROR",
    SET_STOCK_EXCHANGE_LNK_COLUMNS = "SET_STOCK_EXCHANGE_LNK_COLUMNS",

    TOGGLE_NAVIGATION_MENU = "TOGGLE_NAVIGATION_MENU",
    NAVIGATION_SELECTED_KEY = "NAVIGATION_SELECTED_KEY",
}

export const actionCreators = {
    loadStockExchange: createAction<Payload, Payload>(Type.LOAD_STOCK_EXCHANGE, (payload: Payload) => payload),
    loadStockExchangeSuccess: createAction<IPage<StockExchange>, IPage<StockExchange>>(Type.LOAD_STOCK_EXCHANGE_SUCCESS, (stockExchangePage: IPage<StockExchange>) => stockExchangePage),
    loadStockExchangeError: createAction(Type.LOAD_STOCK_EXCHANGE_ERROR),
    setStockExchangeColumns: createAction<ColumnsType<StockExchange>, ColumnsType<StockExchange>>(Type.SET_STOCK_EXCHANGE_COLUMNS, (columns: ColumnsType<StockExchange>) => columns),

    loadStockExchangeLnk: createAction<Payload, ID, Payload>(Type.LOAD_STOCK_EXCHANGE_LNK, (ref: ID, payload: Payload) => ({ ref, ...payload })),
    loadStockExchangeLnkSuccess: createAction<IPage<StockExchangeLnk>, IPage<StockExchangeLnk>>(Type.LOAD_STOCK_EXCHANGE_LNK_SUCCESS, (stockExchangeLnkPage: IPage<StockExchangeLnk>) => stockExchangeLnkPage),
    loadStockExchangeLnkError: createAction(Type.LOAD_STOCK_EXCHANGE_LNK_ERROR),
    setStockExchangeLnkColumns: createAction<ColumnsType<StockExchangeLnk>, ColumnsType<StockExchangeLnk>>(Type.SET_STOCK_EXCHANGE_LNK_COLUMNS, (columns: ColumnsType<StockExchangeLnk>) => columns),

    toggleNavigationOpen: createAction(Type.TOGGLE_NAVIGATION_MENU),
    navigationSelectedKey: createAction<Array<string>, Array<string>>(Type.NAVIGATION_SELECTED_KEY, (payload: Array<string>) => payload)
}