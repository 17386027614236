import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonBack } from 'shared/components/Buttons/ButtonBack';
import { SellerForm } from '../components/SellerForm';
import { SellerInfo } from '../components/SellerInfo';
import { actionCreators } from '../stores';
import { PageContainer } from '../Styles';

/**
*  Details Seller
*/
export const SellerDetails: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionCreators.loadSellerByBirCode());
    }, []);

    return (
        <PageContainer>
            <ButtonBack fallback="/accounts/tracking" />
            <h4>{t("Account.Details.SellerInfo")}</h4>
            <SellerInfo />
            <SellerForm />
        </PageContainer>
    );
}