import React from "react"
import { Input } from "antd";
import { useTranslation } from "react-i18next";

interface InputStringFilterDropdownProps {
    ref: { current: Input | null }
    value: any
    onChange: any
    onPressEnter: any
    title: string
    style: any
}

const InputStringFilterDropdown = (props: InputStringFilterDropdownProps) => {
    const { ref, value, onChange, onPressEnter, title, style } = props;
    const { t } = useTranslation()

    const placeholder = `${t(title).toLowerCase()} ...`;

    return (
        <Input
            ref={ref}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onPressEnter={onPressEnter}
            style={style}
        />
    )
}

export default InputStringFilterDropdown

