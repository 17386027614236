import { Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ButtonSearch, ButtonReset } from "./Buttons"
import InputStringFilterDropdown from "./InputStringFilterDropdown";
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ReactNode } from 'react';

function stringFilterDropdown(title: string) {

  const searchInput: { current: Input | null } = { current: null };
  const dataIndex = title.split('.')[title.split('.').length - 1]
  return {
    filterDropdown:
      ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }: FilterDropdownProps) => (

        <div style={{ padding: 8 }}>
          <InputStringFilterDropdown
            title={title}
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <ButtonSearch onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} />
            <ButtonReset onClick={() => handleReset(clearFilters)} />
          </Space>
        </div>
      ),

    filterIcon: (filtered: any): ReactNode => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

    onFilter: (value: any, record: any): boolean => {
      return true
      // const dataValue = findValueForKey(record, dataIndex);
      // return dataValue
      //   ? dataValue
      //     .toString()
      //     .toLowerCase()
      //     .includes(value.toLowerCase()
      //     ) : ""
    },

    onFilterDropdownVisibleChange: (visible: any): void => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current?.select(), 100);
      }
    }
  }

  // function findValueForKey(data: any, searchKey: any) {
  //   for (const key in data) {
  //     if (key.normalize() === searchKey.normalize()) {
  //       return data[key];
  //     }
  //     if (data[key] instanceof Object) {
  //       const nestedLoop: any = findValueForKey(data[key], searchKey);
  //       if (nestedLoop) {
  //         return nestedLoop;
  //       }
  //     }
  //   }
  //   return false;
  // };

  function handleSearch(selectedKeys: any, confirm: any, dataIndex: any) {
    confirm();
  };

  function handleReset(clearFilters: any) {
    clearFilters();
  };

}

export default stringFilterDropdown;