import { createAction } from 'redux-actions'
import { 
    IAlertMessage, 
    IApiActions, 
    IApiError
} from './types';

export enum Type {
    LOADING_START = '[API] Loading started',
    LOADING_SUCCESS = '[API] Loading success',
    LOADING_FAIL = '[API] Loading failed',
    LOADING_CLEAR = '[API] Loading clear'
}

export const actionsCreators: IApiActions = {
    loadingStart: createAction(Type.LOADING_START),
    loadingSuccess: createAction<
        IAlertMessage | undefined, 
        IAlertMessage | undefined
    >(Type.LOADING_SUCCESS, (alert?: IAlertMessage) => alert),
    loadingFail: createAction<IApiError, IApiError>(
        Type.LOADING_FAIL,
        (error: IApiError) => error
    ),
    loadingClear: createAction(Type.LOADING_CLEAR)
}
