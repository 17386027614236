import { Action, handleActions } from 'redux-actions';
import { BircodeData, RoleData, RoleState } from '.';
import { Type } from './actions';
import { defaultUser, getUserFromLocalStorage, useDefaultUser } from 'shared';
import { getRoleDataFromUser, getBircodeDataFromUser } from "./utils"
import { RpUser } from 'screens/login/stores/types';
import { Country } from 'screens/accounts/stores/types';

const user = useDefaultUser ? new RpUser(defaultUser) : getUserFromLocalStorage();

const INITIAL_STATE: RoleState = {
    allBIRCodeDataList: undefined,
    myBIRCodeData: getBircodeDataFromUser(user),

    roleData: getRoleDataFromUser(user),
    garagePopover: {
        isVisible: false,
        value: ""
    },
    orderPopover: {
        isVisible: false,
        value: "",
        msg: ""
    }
}

export default handleActions<RoleState, any>(
    {
        [Type.SET_ALL_BIRCODE_DATA_LIST]: (state: RoleState, action: Action<Array<Country>>) => {
            let countries = action.payload
            let bircodeDataList: Array<BircodeData> = []
            countries.forEach((country) => bircodeDataList = [...bircodeDataList, ...country.bircodeDataList])

            return {
                ...state,
                loading: false,
                countries,
                allBIRCodeDataList: bircodeDataList
            }
        },
        [Type.SET_MY_BIRCODE_DATA]: (state: RoleState, action: Action<BircodeData>) => ({
            ...state,
            myBIRCodeData: action.payload,
            orderByRefSelected: undefined,
        }),
        [Type.SET_TO_EMPTY_MY_BIRCODE_DATA]: (state: RoleState) => ({
            ...state,
            myBIRCodeData: undefined
        }),
        [Type.SET_MY_ROLE_DATA]: (state: RoleState, action: Action<RoleData>) => ({
            ...state,
            roleData: action.payload
        }),
        [Type.SET_GARAGE_POPOVER_VISIBLE]: (state: RoleState, action: Action<boolean>) => ({
            ...state,
            garagePopover: {
                ...state.garagePopover,
                isVisible: action.payload
            }
        }), [Type.SET_GARAGE_POPOVER_VALUE]: (state: RoleState, action: Action<string>) => ({
            ...state,
            garagePopover: {
                ...state.garagePopover,
                value: action.payload
            }
        }),
        [Type.SET_ORDER_POPOVER_VISIBLE]: (state: RoleState, action: Action<boolean>) => ({
            ...state,
            orderPopover: {
                ...state.orderPopover,
                isVisible: action.payload
            }
        }),
        [Type.SET_ORDER_POPOVER_VALUE]: (state: RoleState, action: Action<string>) => ({
            ...state,
            orderPopover: {
                ...state.orderPopover,
                value: action.payload
            }
        }),
        [Type.SET_ORDER_POPOVER_MSG]: (state: RoleState, action: Action<string>) => ({
            ...state,
            orderPopover: {
                ...state.orderPopover,
                msg: action.payload
            }
        }),
    },
    INITIAL_STATE
);