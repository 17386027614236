import { FC } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Order, actionCreators, orderMassActionStatusSelector, ordersSelectedRowsSelector, OrderStatus } from '../stores';
import { OrderStatusTag } from './OrderStatusTag';
import { Button, Select, Space } from 'antd';
import { ProfileTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Presentation } from "shared/components/Modal/Styles";

const { Option } = Select;

/**
 * Templates for masses actions
 * @returns Menu for @antd/Dropdown
 */
export const OrderTableMassActionModal: FC = () => {
  const ordersSelectedRows = useSelector(ordersSelectedRowsSelector);
  const orderMassActionCurStatus = useSelector(orderMassActionStatusSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const markAllAsRead = () => {
    const filteredRows = ordersSelectedRows
      .filter((o: Order) => o.messageToSeller && !o.isMessageToSellerRead)
      .map((update: Order) => ({ ...update, isMessageToSellerRead: true }));
    dispatch(actionCreators.updateOrders({ orders: filteredRows }));
  };

  const onSelect = (value: OrderStatus) => {
    dispatch(actionCreators.ordersMassactionStatus(value))
  };

  return <Presentation>
    <Space direction="vertical">
      <Space direction="horizontal">
        <Select style={{ width: "175px" }} defaultValue={orderMassActionCurStatus} onChange={onSelect} placeholder={t("Orders.Actions.ActionsToRealise")}>
          <Option value={OrderStatus.Solded} title={t("Orders.Actions.ValidateAllOrders")}>
            {t("Generic.Actions.Validate")}
          </Option>
          <Option value={OrderStatus.R1Refuse} title={t("Orders.Actions.RejectAllOrders")}>
            {t("Generic.Actions.Reject")}
          </Option>
          <Option value={OrderStatus.DMSTreatment} title={t("Orders.Others.ReissueDMS")}>
            {t("Orders.Others.ReissueDMS")}
          </Option>
        </Select>
        <Button
          onClick={markAllAsRead}
          icon={<ProfileTwoTone size={36} />}
          title={t("Orders.Actions.MarkAllAsRead")}
        />
      </Space>
      <p>
        {t("Orders.Actions.OnlyStatusToTreatWillBeModified", {
          toTreat:
            `${t(`Orders.StatusName.${OrderStatus.ValidationRequired}`)}", "${t("Orders.Navigation.OrdersNeedReissueDMS")}`
        })}
      </p>
      {
        ordersSelectedRows?.map(
          order => <>{order.id} <OrderStatusTag status={order.status} /></>
        )
      }
    </Space>
  </Presentation>
};
