import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { actionCreators, navSelectedKey, toggleNavigation } from './stores';

import { Menu } from 'antd';
import { CustomItem, ToggleButton, ToggleWrapper } from './Styles';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Navigation of module ErrorLogs
 */
export const ErrorLogsNavigation: FC = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const showMenu = useSelector(toggleNavigation);
  const selectedKey = useSelector(navSelectedKey);
  const dispatch = useDispatch();
  const toggleCollapsed = () => {
    dispatch(actionCreators.toggleNavigationOpen());
  };

  const handleClick = (e: any) =>
    dispatch(actionCreators.navigationSelectedKey([e.key]));

  return (
    <Menu
      mode="inline"
      inlineCollapsed={showMenu}
      selectedKeys={selectedKey}
      onClick={handleClick}
    >
      <ToggleWrapper $collapsed={showMenu}>
        <ToggleButton
          onClick={toggleCollapsed}
          type="primary"
          $collapsed={showMenu}
        >
          {React.createElement(
            showMenu ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </ToggleButton>
      </ToggleWrapper>
      <CustomItem key="/tracking" icon={<OrderedListOutlined />}>
        <NavLink to={`${url}/tracking`}>{t("Generic.Menu.ErrorLogs")}</NavLink>
      </CustomItem>
    </Menu>
  );
};
