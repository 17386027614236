import { Button, Card, Radio, Row } from "antd";
import styled, { keyframes } from "styled-components";
import { BasicContainer } from "../../Styles";

export const PageContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: 33em;
  width: 85%;
  margin: auto;
  padding: 15px 25px;
`;

export const ToggleButton = styled(Button)`
  width: ${(props: ToggleProps) => props.$collapsed ? '100%' : null};
  animation: ${(props: ToggleProps) => !props.$collapsed ? slideRightAnimation : null} .3s forwards 1;
`;

export const DisplayContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: 33em;
  width: 100%;
`;

export const AccountsTableWrapper = styled.div`
  min-height: 340px;
`;

interface ToggleProps {
  $collapsed: boolean
}

const slideRightAnimation = keyframes`
  from {
    left: 0;
  }
  to {
    left: 152px;
  }
 `;

export const ToggleWrapper = styled.div`
  width: ${(props: ToggleProps) => !props.$collapsed ? '198.36px' : null};
  display: flex;
`;

export const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  background: #F0F2F5;
  padding: .7em;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  margin: 0 2%;
`;

export const InfosRow = styled(Row)`
  display: flex;  
  width: 100%;
  justify-content: space-between;
  background: #F0F2F5;
  padding: .7em;
  margin: 0 !important;
`;

export const DetailsCard = styled(Card)`
  width: 390px;
  .ant-card-body {
    padding: .5em;
  }
`;

export const DetailsCardForm = styled(Card)`
  width: 100%;
  .ant-card-body {
    padding: .5em;
  }
  .ant-picker-input {
    width: 150px;
  }
  .ant-form-item-label>label {
    // width: 200px;
  }
  .ant-form-item {
    dispaly: flex;
    justify-content: space-between;
    width: 467px;
  }
  .ant-form-item-control {
    display: contents;
  }
`;

export const FormDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const DateInline = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const DateTitle = styled.div`
  width: 170px;
`;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Value = styled.span`
  font-weight: bold;
`;

export const ButtonRadio = styled(Radio.Group)`
  position: relative;
  left: 37px;
`;

export const ButtonRadioAccount = styled(Radio.Group)`
  position: relative;
`;

export const VButton = styled(Button)`
`;

export const CustomButton = styled(Button)`
  margin-top: 5px;
  align-self: flex-end;
`;

export const Separator = styled.div`
  width: 2%;
`;

export const Grid = styled(Card)`
  width: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }
`;