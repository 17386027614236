import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { DmsAccountInfo, actionCreators, Buyer } from "../stores";
import { Radio, InputNumber, Form, Select, FormInstance } from 'antd';
import { ButtonRadio, VButton, Value, DetailsCardForm } from '../Styles';
// import { API_BDD } from 'shared';

interface BuyerFormProps {
    data: DmsAccountInfo
    form: FormInstance<any>
    buyer: Buyer
}

export const BuyerForm: FC<BuyerFormProps> = ({ data, form, buyer }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [dmsAccountInfo, setDmsAccountInfo] = useState<DmsAccountInfo>(data)

    const onFinish = () => dispatch(actionCreators.updateDmsInfo(dmsAccountInfo))
    const onValuesChange = (changedValues: any, _: any) => setDmsAccountInfo({ ...dmsAccountInfo, ...changedValues })

    const validateMessages = {
        required: t("Generic.Alert.RequiredField")
    };

    return (
        <DetailsCardForm>
            <div className="fields-wrapper">
                <Form
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    onValuesChange={onValuesChange}
                    initialValues={dmsAccountInfo}
                    form={form}
                >
                    <div className="fields-wrapper">
                        <Form.Item
                            label={t("Account.Details.UpdateDeliveryMode")}
                            name='deliverymethod'
                            rules={[{ required: true }]}
                        >
                            <Select style={{ minWidth: "200px" }}>
                                <Select.Option value="G">{t("Generic.DeliveryMethod.G")}</Select.Option>
                                {
                                    ["ES", "IT"].includes(buyer.state) &&
                                    <Select.Option value="D">{t("Generic.DeliveryMethod.D")}</Select.Option>
                                }
                                <Select.Option value="M">{t("Generic.DeliveryMethod.M")}</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("Account.Details.ClientAutomaticValidation")}
                            name='automaticordertransfer'
                            rules={[{ required: true }]}
                        >
                            <ButtonRadio >
                                <Radio value={"true"} >{t("Generic.Actions.Yes")}</Radio>
                                <Radio value={"false"} >{t("Generic.Actions.No")}</Radio>
                            </ButtonRadio>
                        </Form.Item>
                    </div>
                    <div className="fields-wrapper">
                        <Form.Item
                            label={t("Account.Details.IfYesOrderAmmount")}
                            name="maxorderamount"
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                min={0}
                                type="text"
                            />
                        </Form.Item>

                        <Form.Item
                            // name='actualinvoiceamount'
                            label={t("Account.Details.InDMSFacturation")}
                        >
                            <Value>{dmsAccountInfo.actualinvoiceamount}</Value>
                        </Form.Item>

                        <Form.Item
                            label={t("Account.Details.IfYesMaximumOutstanding")}
                            name='maxinvoiceamount'
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                min={0}
                                type="text"
                            />
                        </Form.Item>

                        {/* {API_BDD === "postgre" && (
                            <Form.Item
                                label={t("Account.Details.CreditLimitation")}
                                name='managecreditlimit'
                                rules={[{ required: true }]}
                            >
                                <ButtonRadio>
                                    <Radio value={"true"}> {t("Generic.Actions.Yes")}</Radio>
                                    <Radio value={"false"}> {t("Generic.Actions.No")}</Radio>
                                </ButtonRadio>
                            </Form.Item>
                        )} */}
                    </div>
                    <VButton type="primary" htmlType="submit">{t("Generic.Actions.Validate")}</VButton>
                </Form >
            </div>
        </DetailsCardForm>
    )

}