import i18n from 'assets/i18n';
import moment from 'moment';
import { api, getArgs, getBirCode, unwrapAxiosResult } from 'shared';
import { API_BDD, API_ORDER_SERVICE } from "shared/utils/constants";
import { ID, IPage, IRestData, Payload } from 'shared/utils/types';
import { Order, OrderEvents, OrderItem, OrderList, UpdateOrderItems } from './types';

const BASE_ORDERS_URI = `${API_ORDER_SERVICE}/orders`

export const ORDERS_URI = {
  BY_SELLER_BIRCODE: (sellerBirCode: string) => `${BASE_ORDERS_URI}/search/by-seller-bir-code?sellerBirCode=${sellerBirCode}`,
  RETRANSMITTED_TO_DMS: (orderId: ID) => `${BASE_ORDERS_URI}/${orderId}/retry`,
  UPDATE_ORDERS_ITEM_WANTED_QUANTITY: (orderId: ID, orderItemId: ID, wantedQuantity: number) => `${BASE_ORDERS_URI}/${orderId}/order-items/${orderItemId}?wantedQuantity=${wantedQuantity}`,
  GET_ORDER_ITEM_BY_ORDER_ID: (orderId: ID) => `${BASE_ORDERS_URI}/${orderId}/order-items`,
  GET_ORDER_ITEM_WITH_STOCKS_BY_ORDER_ID: (orderId: ID) => `${BASE_ORDERS_URI}/${orderId}/order-items?withStocks=true`,
  GET_ORDER_EVENTS_BY_ORDER_ID: (orderId: ID) => `${BASE_ORDERS_URI}/${orderId}/order-events`,
  GET_ORDER_BY_REF: (orderRef: string) => `${BASE_ORDERS_URI}/references/${orderRef}`
}

export const getOrders = (payload: Payload): Promise<IPage<Order>> => unwrapAxiosResult(api.get(`${ORDERS_URI.BY_SELLER_BIRCODE(getBirCode()!)}&${getArgs(payload)}`))
export const putOrder = (payload: Order): Promise<Order> => unwrapAxiosResult(api.put(`${BASE_ORDERS_URI}/${payload.id}`, payload));
export const putOrders = (payload: OrderList): Promise<OrderList> => unwrapAxiosResult(api.put(BASE_ORDERS_URI, payload));
export const retransmissionDMS = (payload: ID): Promise<Order> => unwrapAxiosResult(api.post(ORDERS_URI.RETRANSMITTED_TO_DMS(payload)));
export const orderItemsWantedQuantity = (payload: UpdateOrderItems): Promise<OrderItem> => unwrapAxiosResult(api.patch(ORDERS_URI.UPDATE_ORDERS_ITEM_WANTED_QUANTITY(payload.orderId, payload.orderItemId, payload.fields.wantedQuantity)))
export const getOrderItemsByOrderId = (payload: ID): Promise<IRestData<OrderItem>> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_ITEM_BY_ORDER_ID(payload)))
export const getOrderItemsWithStocksByOrderId = (payload: ID): Promise<IRestData<OrderItem>> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_ITEM_WITH_STOCKS_BY_ORDER_ID(payload)))
export const getOrderEventsByOrderId = (payload: ID): Promise<IRestData<OrderItem>> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_EVENTS_BY_ORDER_ID(payload)))
export const getByRef = (payload: string): Promise<Order> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_BY_REF(payload)));

export const mapOrderEvents = (orderEvents: OrderEvents[]) => {
  if (orderEvents) {
    const baseEvents: any = orderEvents[0];
    if (API_BDD === "oracle") {
      let resEvents: Array<any> = [];
      baseEvents?.eventDate.forEach(
        (eventDate: Date, index: number) => {
          if (eventDate)
            resEvents = [...resEvents, {
              key: index,
              eventDate: moment(eventDate).format('L LT'),
              eventType: baseEvents.eventType && baseEvents.eventType[index] ? i18n.t(`Orders.Status.${baseEvents.eventType[index]}`) : null,
              eventComment: baseEvents.eventComment && baseEvents.eventComment[index] ? baseEvents.eventComment[index] : null,
              isNew: baseEvents.isNew && baseEvents?.isNew[index] ? baseEvents.isNew[index] : null,
              orderAccepted: baseEvents.orderAccepted && baseEvents.orderAccepted[index] ? baseEvents.orderAccepted[index] : null
            }]
        }
      )
      return resEvents;
    }
    else
      return orderEvents.map(
        (event: OrderEvents, index: number) => {
          return {
            ...event,
            eventDate: moment(event.eventDate).format('L LT'),
            eventType: i18n.t(`Orders.Status.${event.eventType}`),
            key: index,
          };
        }
      )
  }
}