import { Menu } from 'antd';
import styled from 'styled-components';
import { COLORS } from '.';
import { BasicContainer } from '../Styles';

/**
 * Common Styles used to design rp4 pages
 */
export const MainContainer = styled.div`
    top:35px; 
    height: calc(100% - 35px);
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${COLORS.LIGHT_GREY};
`;

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 35px;
    padding-top: 35px;
`;

export const PageContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: 33em;
  width: 100%;
  padding: 15px 25px;
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const RPNavigation = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    height: 142px;
    margin-right: .5em;
`;

export const Title = styled.h2`
    color: #FC3;
    font-weight: 700;
`;

export const BottomPart = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const CustomItem = styled(Menu.Item)`
  color: #000;
`;