import { Space, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ButtonBack } from "shared/components/Buttons/ButtonBack";
import { PageContainer } from "shared/GlobalStyles";
import { useConfigTranslate } from "shared/hooks/useConfigTranslate";
import { StockExchangeLnkTable } from "../components/StockExchangeLnkTable";
import { useStockExchangeLnkColumns } from "../hooks/useStockExchangeLnkColumns"
import { actionCreators } from "../stores/actions";
import { defaultStockExchangeLnkPayload } from "../stores/reducer"
import { stockExchangeLnkPayloadSelector, stockExchangeLnkColumnsSelector } from "../stores/selectors";
import { StockExchangeLnk, StockExchangeLnkRequestPayload } from "../stores/types";

const StockExchangeLnkTracking: FC = () => {
    const { ref, countrycode } = useParams<{ ref: string, countrycode: string }>();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const defaultColumns = useStockExchangeLnkColumns();
    const payloadSelector = useSelector(stockExchangeLnkPayloadSelector)
    const columnsSelector = useSelector(stockExchangeLnkColumnsSelector)

    const [columnsState, setColumnsState] = useState(columnsSelector ? columnsSelector : defaultColumns);
    const [payloadState, setPayloadState] = useState(payloadSelector);

    const setPayload = (payload: StockExchangeLnkRequestPayload) => dispatch(actionCreators.loadStockExchangeLnk(ref, {
        ...payload,
        countrycode
    }))
    const setColumns = (columns: ColumnsType<StockExchangeLnk>) => dispatch(actionCreators.setStockExchangeLnkColumns(columns))
    const [key, setKey] = useState(0);


    useEffect(() => { setPayload(payloadState) }, [payloadState])
    useEffect(() => { setColumns(columnsState) }, [columnsState])

    const locale = useConfigTranslate();
    useEffect(() => {
        handleReinitFilters()
    }, [locale])

    const handleReinitFilters = () => {
        reinitTab()
        setKey(key + 1)
    }

    const reinitTab = () => {
        setColumns(defaultColumns)
        setPayload(defaultStockExchangeLnkPayload)
    }

    return <PageContainer>
        <Space>
            <ButtonBack fallback={"/stock-exchange/tracking"} />
        </Space>
        <Tabs>
            <Tabs.TabPane
                tab={t("StockExchange.Navigation.LnkTabs") + " > " + countrycode + " - " + ref}
                key={key}
            >
                <StockExchangeLnkTable
                    columns={columnsState}
                    payload={payloadState}
                    setColumns={setColumnsState}
                    setPayload={setPayloadState}
                />
            </Tabs.TabPane>
        </Tabs>
    </PageContainer>
}

export default StockExchangeLnkTracking