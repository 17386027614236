import { Route } from 'shared/utils/types';
import StockExchangeLnkTracking from './pages/StockExchangeLnkTracking';
import StockExchangeTracking from './pages/StockExchangeTracking';

export const StockExchangeRoutes: Route[] = [
    {
        name: "tracking",
        path: "/tracking",
        component: StockExchangeTracking,
        isProtected: true,
        isBircodeRequired: false
    },
    {
        name: "lnk",
        path: "/lnk/:countrycode/:ref",
        component: StockExchangeLnkTracking,
        isProtected: true
    }
];
