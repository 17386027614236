import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

function renderWithLink<T>(renderFunc: any, linkByRecordFunc: any) {
    return (value: any, record: T) =>
        <Link
            style={{ color: "unset", width: "100%", display: "block" }}
            to={() => linkByRecordFunc(record)}>{renderFunc ? renderFunc(value, record) : value}
        </Link>
}

export function useRowAsLink<T>(columns: ColumnsType<T>, linkByRecordFunc: any) {
    return columns.map(column => ({
        ...column,
        render: renderWithLink(column.render, linkByRecordFunc)
    }))
}